/* tslint:disable */
/* eslint-disable */
/**
 * Compliance Crow API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2018-05-10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CancelPlmInstanceDeletionRequestContent
 */
export interface CancelPlmInstanceDeletionRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CancelPlmInstanceDeletionRequestContent
     */
    'plmId': string;
}
/**
 * 
 * @export
 * @interface CancelPlmInstanceDeletionResponseContent
 */
export interface CancelPlmInstanceDeletionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CancelPlmInstanceDeletionResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreateAllMissingSubstancesRequestContent
 */
export interface CreateAllMissingSubstancesRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateAllMissingSubstancesRequestContent
     */
    'plmId': string;
}
/**
 * 
 * @export
 * @interface CreateAllMissingSubstancesResponseContent
 */
export interface CreateAllMissingSubstancesResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreateAllMissingSubstancesResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreateInternalAttributeRequestContent
 */
export interface CreateInternalAttributeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateInternalAttributeRequestContent
     */
    'attributeName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalAttributeRequestContent
     */
    'attributeType': string;
}
/**
 * 
 * @export
 * @interface CreateInternalAttributeResponseContent
 */
export interface CreateInternalAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreateInternalAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreateMissingSubstanceRequestContent
 */
export interface CreateMissingSubstanceRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateMissingSubstanceRequestContent
     */
    'plmId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMissingSubstanceRequestContent
     */
    'substanceId': string;
}
/**
 * 
 * @export
 * @interface CreateMissingSubstanceResponseContent
 */
export interface CreateMissingSubstanceResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreateMissingSubstanceResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreatePlmAttributeRequestContent
 */
export interface CreatePlmAttributeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreatePlmAttributeRequestContent
     */
    'plmId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlmAttributeRequestContent
     */
    'attributeName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlmAttributeRequestContent
     */
    'attributeType': string;
}
/**
 * 
 * @export
 * @interface CreatePlmAttributeResponseContent
 */
export interface CreatePlmAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreatePlmAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreatePlmInstanceRequestContent
 */
export interface CreatePlmInstanceRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreatePlmInstanceRequestContent
     */
    'plmId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlmInstanceRequestContent
     */
    'plmType': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlmInstanceRequestContent
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlmInstanceRequestContent
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface CreatePlmInstanceResponseContent
 */
export interface CreatePlmInstanceResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreatePlmInstanceResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreateProviderAttributeRequestContent
 */
export interface CreateProviderAttributeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderAttributeRequestContent
     */
    'attributeName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderAttributeRequestContent
     */
    'attributeType': string;
}
/**
 * 
 * @export
 * @interface CreateProviderAttributeResponseContent
 */
export interface CreateProviderAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface CreateSubstanceAliasRequestContent
 */
export interface CreateSubstanceAliasRequestContent {
    /**
     * 
     * @type {string}
     * @memberof CreateSubstanceAliasRequestContent
     */
    'substanceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubstanceAliasRequestContent
     */
    'alias': string;
}
/**
 * 
 * @export
 * @interface CreateSubstanceAliasResponseContent
 */
export interface CreateSubstanceAliasResponseContent {
    /**
     * 
     * @type {string}
     * @memberof CreateSubstanceAliasResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface DeleteInternalAttributeResponseContent
 */
export interface DeleteInternalAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof DeleteInternalAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface DeletePlmAttributeResponseContent
 */
export interface DeletePlmAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof DeletePlmAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface DeleteProviderAttributeResponseContent
 */
export interface DeleteProviderAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof DeleteProviderAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * This exception is thrown on a dependent service failure
 * @export
 * @interface DependencyServiceExceptionResponseContent
 */
export interface DependencyServiceExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof DependencyServiceExceptionResponseContent
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface EchoResponseContent
 */
export interface EchoResponseContent {
    /**
     * 
     * @type {string}
     * @memberof EchoResponseContent
     */
    'string': string;
}
/**
 * 
 * @export
 * @interface GetInternalAttributesResponseContent
 */
export interface GetInternalAttributesResponseContent {
    /**
     * 
     * @type {Array<PartAttribute>}
     * @memberof GetInternalAttributesResponseContent
     */
    'results': Array<PartAttribute>;
}
/**
 * 
 * @export
 * @interface GetPlmAttributeMappingsResponseContent
 */
export interface GetPlmAttributeMappingsResponseContent {
    /**
     * 
     * @type {Array<PlmAttributeMapping>}
     * @memberof GetPlmAttributeMappingsResponseContent
     */
    'attributes': Array<PlmAttributeMapping>;
}
/**
 * 
 * @export
 * @interface GetPlmAttributesResponseContent
 */
export interface GetPlmAttributesResponseContent {
    /**
     * 
     * @type {Array<PartAttribute>}
     * @memberof GetPlmAttributesResponseContent
     */
    'results': Array<PartAttribute>;
}
/**
 * 
 * @export
 * @interface GetPlmInstancesResponseContent
 */
export interface GetPlmInstancesResponseContent {
    /**
     * 
     * @type {Array<PlmInstance>}
     * @memberof GetPlmInstancesResponseContent
     */
    'plmInstances': Array<PlmInstance>;
}
/**
 * 
 * @export
 * @interface GetProviderAttributeMappingsResponseContent
 */
export interface GetProviderAttributeMappingsResponseContent {
    /**
     * 
     * @type {Array<ProviderAttributeMapping>}
     * @memberof GetProviderAttributeMappingsResponseContent
     */
    'attributes': Array<ProviderAttributeMapping>;
}
/**
 * 
 * @export
 * @interface GetProviderAttributesResponseContent
 */
export interface GetProviderAttributesResponseContent {
    /**
     * 
     * @type {Array<PartAttribute>}
     * @memberof GetProviderAttributesResponseContent
     */
    'results': Array<PartAttribute>;
}
/**
 * 
 * @export
 * @interface GetSubstancesResponseContent
 */
export interface GetSubstancesResponseContent {
    /**
     * 
     * @type {Array<RetrievedSubstanceData>}
     * @memberof GetSubstancesResponseContent
     */
    'substances': Array<RetrievedSubstanceData>;
}
/**
 * 
 * @export
 * @interface IndividualUpdatePart
 */
export interface IndividualUpdatePart {
    /**
     * 
     * @type {string}
     * @memberof IndividualUpdatePart
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof IndividualUpdatePart
     */
    'manufacturerPartNumber': string;
}
/**
 * This exception is thrown on an invalid input error
 * @export
 * @interface InvalidInputExceptionResponseContent
 */
export interface InvalidInputExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof InvalidInputExceptionResponseContent
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface MapPlmAttributeRequestContent
 */
export interface MapPlmAttributeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof MapPlmAttributeRequestContent
     */
    'internalAttribute': string;
    /**
     * 
     * @type {string}
     * @memberof MapPlmAttributeRequestContent
     */
    'plmId': string;
    /**
     * 
     * @type {string}
     * @memberof MapPlmAttributeRequestContent
     */
    'plmAttribute': string;
}
/**
 * 
 * @export
 * @interface MapPlmAttributeResponseContent
 */
export interface MapPlmAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof MapPlmAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface MapProviderAttributeRequestContent
 */
export interface MapProviderAttributeRequestContent {
    /**
     * 
     * @type {string}
     * @memberof MapProviderAttributeRequestContent
     */
    'internalAttribute': string;
    /**
     * 
     * @type {string}
     * @memberof MapProviderAttributeRequestContent
     */
    'dataProviderAttribute': string;
}
/**
 * 
 * @export
 * @interface MapProviderAttributeResponseContent
 */
export interface MapProviderAttributeResponseContent {
    /**
     * 
     * @type {string}
     * @memberof MapProviderAttributeResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface PartAttribute
 */
export interface PartAttribute {
    /**
     * 
     * @type {string}
     * @memberof PartAttribute
     */
    'attributeName': string;
}
/**
 * Data provider subscription status of an individual part
 * @export
 * @enum {string}
 */

export const PartSubscriptionStatus = {
    NotSubscribed: 'NOT_SUBSCRIBED',
    Subscribed: 'SUBSCRIBED',
    Removed: 'REMOVED'
} as const;

export type PartSubscriptionStatus = typeof PartSubscriptionStatus[keyof typeof PartSubscriptionStatus];

/**
 * 
 * @export
 * @interface PlmAttributeMapping
 */
export interface PlmAttributeMapping {
    /**
     * 
     * @type {string}
     * @memberof PlmAttributeMapping
     */
    'internalAttribute': string;
    /**
     * 
     * @type {string}
     * @memberof PlmAttributeMapping
     */
    'plmAttribute': string;
}
/**
 * 
 * @export
 * @interface PlmInstance
 */
export interface PlmInstance {
    /**
     * 
     * @type {string}
     * @memberof PlmInstance
     */
    'plmId': string;
    /**
     * 
     * @type {string}
     * @memberof PlmInstance
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlmInstance
     */
    'plmType': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlmInstance
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlmInstance
     */
    'scheduledDeletionDate': string;
}
/**
 * 
 * @export
 * @interface ProviderAttributeMapping
 */
export interface ProviderAttributeMapping {
    /**
     * 
     * @type {string}
     * @memberof ProviderAttributeMapping
     */
    'internalAttribute': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAttributeMapping
     */
    'providerAttribute': string;
}
/**
 * 
 * @export
 * @interface RetrievePartsResponseContent
 */
export interface RetrievePartsResponseContent {
    /**
     * 
     * @type {number}
     * @memberof RetrievePartsResponseContent
     */
    'count': number;
    /**
     * 
     * @type {Array<RetrievedPartData>}
     * @memberof RetrievePartsResponseContent
     */
    'parts': Array<RetrievedPartData>;
}
/**
 * 
 * @export
 * @interface RetrievedPartData
 */
export interface RetrievedPartData {
    /**
     * 
     * @type {number}
     * @memberof RetrievedPartData
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'dataProviderPartId': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'internalPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'partType': string;
    /**
     * 
     * @type {PartSubscriptionStatus}
     * @memberof RetrievedPartData
     */
    'subscriptionStatus': PartSubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'subscriptionUpdateDate': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedPartData
     */
    'plmPartId': string;
}
/**
 * 
 * @export
 * @interface RetrievedSubstanceData
 */
export interface RetrievedSubstanceData {
    /**
     * 
     * @type {string}
     * @memberof RetrievedSubstanceData
     */
    'substanceId': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedSubstanceData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedSubstanceData
     */
    'casNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedSubstanceData
     */
    'dataProviderNumber': string;
}
/**
 * 
 * @export
 * @interface SchedulePlmInstanceDeletionResponseContent
 */
export interface SchedulePlmInstanceDeletionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof SchedulePlmInstanceDeletionResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface SearchPartsResponseContent
 */
export interface SearchPartsResponseContent {
    /**
     * 
     * @type {number}
     * @memberof SearchPartsResponseContent
     */
    'count': number;
    /**
     * 
     * @type {Array<RetrievedPartData>}
     * @memberof SearchPartsResponseContent
     */
    'parts': Array<RetrievedPartData>;
}
/**
 * 
 * @export
 * @interface SearchSubstancesResponseContent
 */
export interface SearchSubstancesResponseContent {
    /**
     * 
     * @type {Array<RetrievedSubstanceData>}
     * @memberof SearchSubstancesResponseContent
     */
    'substances': Array<RetrievedSubstanceData>;
}
/**
 * 
 * @export
 * @interface SubscribePartInput
 */
export interface SubscribePartInput {
    /**
     * 
     * @type {string}
     * @memberof SubscribePartInput
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePartInput
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePartInput
     */
    'internalPartNumber'?: string;
}
/**
 * 
 * @export
 * @interface SubscribePartOutput
 */
export interface SubscribePartOutput {
    /**
     * 
     * @type {string}
     * @memberof SubscribePartOutput
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePartOutput
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {PartSubscriptionStatus}
     * @memberof SubscribePartOutput
     */
    'subscriptionStatus': PartSubscriptionStatus;
}
/**
 * 
 * @export
 * @interface SubscribePartRequestContent
 */
export interface SubscribePartRequestContent {
    /**
     * 
     * @type {string}
     * @memberof SubscribePartRequestContent
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePartRequestContent
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePartRequestContent
     */
    'internalPartNumber'?: string;
}
/**
 * 
 * @export
 * @interface SubscribePartResponseContent
 */
export interface SubscribePartResponseContent {
    /**
     * 
     * @type {string}
     * @memberof SubscribePartResponseContent
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePartResponseContent
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {PartSubscriptionStatus}
     * @memberof SubscribePartResponseContent
     */
    'subscriptionStatus': PartSubscriptionStatus;
}
/**
 * 
 * @export
 * @interface SubscribePartsListRequestContent
 */
export interface SubscribePartsListRequestContent {
    /**
     * 
     * @type {Array<SubscribePartInput>}
     * @memberof SubscribePartsListRequestContent
     */
    'parts': Array<SubscribePartInput>;
}
/**
 * 
 * @export
 * @interface SubscribePartsListResponseContent
 */
export interface SubscribePartsListResponseContent {
    /**
     * 
     * @type {Array<SubscribePartOutput>}
     * @memberof SubscribePartsListResponseContent
     */
    'parts': Array<SubscribePartOutput>;
}
/**
 * 
 * @export
 * @interface UnsubscribePartInput
 */
export interface UnsubscribePartInput {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartInput
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartInput
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartInput
     */
    'internalPartNumber': string;
}
/**
 * 
 * @export
 * @interface UnsubscribePartOutput
 */
export interface UnsubscribePartOutput {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartOutput
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartOutput
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {PartSubscriptionStatus}
     * @memberof UnsubscribePartOutput
     */
    'subscriptionStatus': PartSubscriptionStatus;
}
/**
 * 
 * @export
 * @interface UnsubscribePartRequestContent
 */
export interface UnsubscribePartRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartRequestContent
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartRequestContent
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartRequestContent
     */
    'internalPartNumber': string;
}
/**
 * 
 * @export
 * @interface UnsubscribePartResponseContent
 */
export interface UnsubscribePartResponseContent {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartResponseContent
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePartResponseContent
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {PartSubscriptionStatus}
     * @memberof UnsubscribePartResponseContent
     */
    'subscriptionStatus': PartSubscriptionStatus;
}
/**
 * 
 * @export
 * @interface UnsubscribePartsListRequestContent
 */
export interface UnsubscribePartsListRequestContent {
    /**
     * 
     * @type {Array<UnsubscribePartInput>}
     * @memberof UnsubscribePartsListRequestContent
     */
    'parts': Array<UnsubscribePartInput>;
}
/**
 * 
 * @export
 * @interface UnsubscribePartsListResponseContent
 */
export interface UnsubscribePartsListResponseContent {
    /**
     * 
     * @type {Array<UnsubscribePartOutput>}
     * @memberof UnsubscribePartsListResponseContent
     */
    'parts': Array<UnsubscribePartOutput>;
}
/**
 * 
 * @export
 * @interface UpdateAllPartsRequestContent
 */
export interface UpdateAllPartsRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllPartsRequestContent
     */
    'plmId': string;
}
/**
 * 
 * @export
 * @interface UpdateAllPartsResponseContent
 */
export interface UpdateAllPartsResponseContent {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllPartsResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface UpdatePartListRequestContent
 */
export interface UpdatePartListRequestContent {
    /**
     * 
     * @type {Array<IndividualUpdatePart>}
     * @memberof UpdatePartListRequestContent
     */
    'parts': Array<IndividualUpdatePart>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartListRequestContent
     */
    'plmId': string;
}
/**
 * 
 * @export
 * @interface UpdatePartListResponseContent
 */
export interface UpdatePartListResponseContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePartListResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface UpdatePartRequestContent
 */
export interface UpdatePartRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePartRequestContent
     */
    'manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartRequestContent
     */
    'manufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartRequestContent
     */
    'plmId': string;
}
/**
 * 
 * @export
 * @interface UpdatePartResponseContent
 */
export interface UpdatePartResponseContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePartResponseContent
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface UpdatePlmInstanceRequestContent
 */
export interface UpdatePlmInstanceRequestContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlmInstanceRequestContent
     */
    'plmId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlmInstanceRequestContent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlmInstanceRequestContent
     */
    'plmType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlmInstanceRequestContent
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdatePlmInstanceResponseContent
 */
export interface UpdatePlmInstanceResponseContent {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlmInstanceResponseContent
     */
    'statusMessage': string;
}

/**
 * CORSApi - axios parameter creator
 * @export
 */
export const CORSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesInternal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesMappingPlm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/mapping/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesMappingProvider: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/mapping/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesPlm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesProvider: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEcho: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/echo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsParts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsSearch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsSubscribeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/subscribe/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsSubscribeSingle: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/subscribe/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUnsubscribeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/unsubscribe/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUnsubscribeSingle: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/unsubscribe/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUpdateList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/update/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUpdateSingle: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts/update/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPlmInstance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plm/instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPlmInstanceCanceldeletion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plm/instance/canceldeletion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstances: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/substances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesAlias: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/substances/alias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesMissing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/substances/missing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesMissingAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/substances/missing/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesSearch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/substances/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CORSApi - functional programming interface
 * @export
 */
export const CORSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CORSApiAxiosParamCreator(configuration)
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsAttributesInternal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsAttributesInternal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsAttributesMappingPlm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsAttributesMappingPlm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsAttributesMappingProvider(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsAttributesMappingProvider(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsAttributesPlm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsAttributesPlm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsAttributesProvider(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsAttributesProvider(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsEcho(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsEcho(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsParts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsParts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsSearch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsSearch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsSubscribeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsSubscribeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsSubscribeSingle(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsSubscribeSingle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsUnsubscribeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsUnsubscribeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsUnsubscribeSingle(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsUnsubscribeSingle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsUpdateList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsUpdateList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPartsUpdateSingle(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPartsUpdateSingle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPlmInstance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPlmInstance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsPlmInstanceCanceldeletion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsPlmInstanceCanceldeletion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSubstances(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSubstances(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSubstancesAlias(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSubstancesAlias(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSubstancesMissing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSubstancesMissing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSubstancesMissingAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSubstancesMissingAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corsSubstancesSearch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corsSubstancesSearch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CORSApi - factory interface
 * @export
 */
export const CORSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CORSApiFp(configuration)
    return {
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesInternal(options?: any): AxiosPromise<void> {
            return localVarFp.corsAttributesInternal(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesMappingPlm(options?: any): AxiosPromise<void> {
            return localVarFp.corsAttributesMappingPlm(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesMappingProvider(options?: any): AxiosPromise<void> {
            return localVarFp.corsAttributesMappingProvider(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesPlm(options?: any): AxiosPromise<void> {
            return localVarFp.corsAttributesPlm(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsAttributesProvider(options?: any): AxiosPromise<void> {
            return localVarFp.corsAttributesProvider(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsEcho(options?: any): AxiosPromise<void> {
            return localVarFp.corsEcho(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsParts(options?: any): AxiosPromise<void> {
            return localVarFp.corsParts(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsSearch(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsSearch(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsSubscribeList(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsSubscribeList(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsSubscribeSingle(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsSubscribeSingle(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUnsubscribeList(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsUnsubscribeList(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUnsubscribeSingle(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsUnsubscribeSingle(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUpdate(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUpdateList(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsUpdateList(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPartsUpdateSingle(options?: any): AxiosPromise<void> {
            return localVarFp.corsPartsUpdateSingle(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPlmInstance(options?: any): AxiosPromise<void> {
            return localVarFp.corsPlmInstance(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsPlmInstanceCanceldeletion(options?: any): AxiosPromise<void> {
            return localVarFp.corsPlmInstanceCanceldeletion(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstances(options?: any): AxiosPromise<void> {
            return localVarFp.corsSubstances(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesAlias(options?: any): AxiosPromise<void> {
            return localVarFp.corsSubstancesAlias(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesMissing(options?: any): AxiosPromise<void> {
            return localVarFp.corsSubstancesMissing(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesMissingAll(options?: any): AxiosPromise<void> {
            return localVarFp.corsSubstancesMissingAll(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles CORS-preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corsSubstancesSearch(options?: any): AxiosPromise<void> {
            return localVarFp.corsSubstancesSearch(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CORSApi - object-oriented interface
 * @export
 * @class CORSApi
 * @extends {BaseAPI}
 */
export class CORSApi extends BaseAPI {
    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsAttributesInternal(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsAttributesInternal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsAttributesMappingPlm(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsAttributesMappingPlm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsAttributesMappingProvider(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsAttributesMappingProvider(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsAttributesPlm(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsAttributesPlm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsAttributesProvider(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsAttributesProvider(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsEcho(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsEcho(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsParts(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsParts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsSearch(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsSearch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsSubscribeList(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsSubscribeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsSubscribeSingle(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsSubscribeSingle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsUnsubscribeList(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsUnsubscribeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsUnsubscribeSingle(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsUnsubscribeSingle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsUpdate(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsUpdateList(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsUpdateList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPartsUpdateSingle(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPartsUpdateSingle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPlmInstance(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPlmInstance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsPlmInstanceCanceldeletion(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsPlmInstanceCanceldeletion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSubstances(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSubstances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSubstancesAlias(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSubstancesAlias(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSubstancesMissing(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSubstancesMissing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSubstancesMissingAll(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSubstancesMissingAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles CORS-preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public corsSubstancesSearch(options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).corsSubstancesSearch(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancel a PLM instance\'s deletion
         * @param {CancelPlmInstanceDeletionRequestContent} cancelPlmInstanceDeletionRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPlmInstanceDeletion: async (cancelPlmInstanceDeletionRequestContent: CancelPlmInstanceDeletionRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelPlmInstanceDeletionRequestContent' is not null or undefined
            assertParamExists('cancelPlmInstanceDeletion', 'cancelPlmInstanceDeletionRequestContent', cancelPlmInstanceDeletionRequestContent)
            const localVarPath = `/plm/instance/canceldeletion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelPlmInstanceDeletionRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create all missing substances in a specified PLM instance
         * @param {CreateAllMissingSubstancesRequestContent} createAllMissingSubstancesRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllMissingSubstances: async (createAllMissingSubstancesRequestContent: CreateAllMissingSubstancesRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllMissingSubstancesRequestContent' is not null or undefined
            assertParamExists('createAllMissingSubstances', 'createAllMissingSubstancesRequestContent', createAllMissingSubstancesRequestContent)
            const localVarPath = `/substances/missing/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllMissingSubstancesRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an internally-stored attribute
         * @param {CreateInternalAttributeRequestContent} createInternalAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalAttribute: async (createInternalAttributeRequestContent: CreateInternalAttributeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInternalAttributeRequestContent' is not null or undefined
            assertParamExists('createInternalAttribute', 'createInternalAttributeRequestContent', createInternalAttributeRequestContent)
            const localVarPath = `/attributes/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInternalAttributeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a missing substance in a specified PLM instance
         * @param {CreateMissingSubstanceRequestContent} createMissingSubstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingSubstance: async (createMissingSubstanceRequestContent: CreateMissingSubstanceRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMissingSubstanceRequestContent' is not null or undefined
            assertParamExists('createMissingSubstance', 'createMissingSubstanceRequestContent', createMissingSubstanceRequestContent)
            const localVarPath = `/substances/missing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMissingSubstanceRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an attribute retrieved by a specified PLM instance
         * @param {CreatePlmAttributeRequestContent} createPlmAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlmAttribute: async (createPlmAttributeRequestContent: CreatePlmAttributeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlmAttributeRequestContent' is not null or undefined
            assertParamExists('createPlmAttribute', 'createPlmAttributeRequestContent', createPlmAttributeRequestContent)
            const localVarPath = `/attributes/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlmAttributeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a PLM instance
         * @param {CreatePlmInstanceRequestContent} createPlmInstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlmInstance: async (createPlmInstanceRequestContent: CreatePlmInstanceRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlmInstanceRequestContent' is not null or undefined
            assertParamExists('createPlmInstance', 'createPlmInstanceRequestContent', createPlmInstanceRequestContent)
            const localVarPath = `/plm/instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlmInstanceRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a provider attribute
         * @param {CreateProviderAttributeRequestContent} createProviderAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderAttribute: async (createProviderAttributeRequestContent: CreateProviderAttributeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderAttributeRequestContent' is not null or undefined
            assertParamExists('createProviderAttribute', 'createProviderAttributeRequestContent', createProviderAttributeRequestContent)
            const localVarPath = `/attributes/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderAttributeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a substance alias
         * @param {CreateSubstanceAliasRequestContent} createSubstanceAliasRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubstanceAlias: async (createSubstanceAliasRequestContent: CreateSubstanceAliasRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubstanceAliasRequestContent' is not null or undefined
            assertParamExists('createSubstanceAlias', 'createSubstanceAliasRequestContent', createSubstanceAliasRequestContent)
            const localVarPath = `/substances/alias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubstanceAliasRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an internally-stored attribute
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInternalAttribute: async (attributename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributename' is not null or undefined
            assertParamExists('deleteInternalAttribute', 'attributename', attributename)
            const localVarPath = `/attributes/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (attributename !== undefined) {
                localVarQueryParameter['attributename'] = attributename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an attribute retrieved by a specified PLM instance
         * @param {string} plmid 
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlmAttribute: async (plmid: string, attributename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plmid' is not null or undefined
            assertParamExists('deletePlmAttribute', 'plmid', plmid)
            // verify required parameter 'attributename' is not null or undefined
            assertParamExists('deletePlmAttribute', 'attributename', attributename)
            const localVarPath = `/attributes/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }

            if (attributename !== undefined) {
                localVarQueryParameter['attributename'] = attributename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a provider attribute
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProviderAttribute: async (attributename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributename' is not null or undefined
            assertParamExists('deleteProviderAttribute', 'attributename', attributename)
            const localVarPath = `/attributes/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (attributename !== undefined) {
                localVarQueryParameter['attributename'] = attributename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a string input and provides it back to the requester
         * @param {string} string 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        echo: async (string: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'string' is not null or undefined
            assertParamExists('echo', 'string', string)
            const localVarPath = `/echo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (string !== undefined) {
                localVarQueryParameter['string'] = string;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of all internally-stored attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAttributes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all mappings between internal attributes and a specified PLM instance\'s attributes
         * @param {number} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlmAttributeMappings: async (plmid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plmid' is not null or undefined
            assertParamExists('getPlmAttributeMappings', 'plmid', plmid)
            const localVarPath = `/attributes/mapping/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of attributes configured for a specified PLM instance
         * @param {string} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlmAttributes: async (plmid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plmid' is not null or undefined
            assertParamExists('getPlmAttributes', 'plmid', plmid)
            const localVarPath = `/attributes/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all PLM instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlmInstances: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plm/instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all mappings between internal attributes and data provider attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAttributeMappings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/mapping/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of attributes provided by the data provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAttributes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all substances
         * @param {string} [status] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubstances: async (status?: string, plmid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/substances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Map an internal attribute to an attribute retrieved by a specified PLM instance
         * @param {MapPlmAttributeRequestContent} mapPlmAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapPlmAttribute: async (mapPlmAttributeRequestContent: MapPlmAttributeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapPlmAttributeRequestContent' is not null or undefined
            assertParamExists('mapPlmAttribute', 'mapPlmAttributeRequestContent', mapPlmAttributeRequestContent)
            const localVarPath = `/attributes/mapping/plm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapPlmAttributeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Map an internal attribute to a data provider attribute
         * @param {MapProviderAttributeRequestContent} mapProviderAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapProviderAttribute: async (mapProviderAttributeRequestContent: MapProviderAttributeRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapProviderAttributeRequestContent' is not null or undefined
            assertParamExists('mapProviderAttribute', 'mapProviderAttributeRequestContent', mapProviderAttributeRequestContent)
            const localVarPath = `/attributes/mapping/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapProviderAttributeRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of parts
         * @param {number} [page] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParts: async (page?: number, plmid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Schedule a PLM instance for deletion
         * @param {string} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulePlmInstanceDeletion: async (plmid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plmid' is not null or undefined
            assertParamExists('schedulePlmInstanceDeletion', 'plmid', plmid)
            const localVarPath = `/plm/instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches parts by the provided field(s)
         * @param {string} field 
         * @param {string} query 
         * @param {number} [page] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchParts: async (field: string, query: string, page?: number, plmid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'field' is not null or undefined
            assertParamExists('searchParts', 'field', field)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchParts', 'query', query)
            const localVarPath = `/parts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for substances
         * @param {string} field 
         * @param {string} query 
         * @param {string} [status] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSubstances: async (field: string, query: string, status?: string, plmid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'field' is not null or undefined
            assertParamExists('searchSubstances', 'field', field)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchSubstances', 'query', query)
            const localVarPath = `/substances/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (plmid !== undefined) {
                localVarQueryParameter['plmid'] = plmid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a single part to the compliance data provider subscription
         * @param {SubscribePartRequestContent} subscribePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePart: async (subscribePartRequestContent: SubscribePartRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribePartRequestContent' is not null or undefined
            assertParamExists('subscribePart', 'subscribePartRequestContent', subscribePartRequestContent)
            const localVarPath = `/parts/subscribe/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribePartRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a list of parts to the compliance data provider subscription
         * @param {SubscribePartsListRequestContent} subscribePartsListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePartsList: async (subscribePartsListRequestContent: SubscribePartsListRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribePartsListRequestContent' is not null or undefined
            assertParamExists('subscribePartsList', 'subscribePartsListRequestContent', subscribePartsListRequestContent)
            const localVarPath = `/parts/subscribe/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribePartsListRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a single part from the compliance data provider subscription
         * @param {UnsubscribePartRequestContent} unsubscribePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribePart: async (unsubscribePartRequestContent: UnsubscribePartRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unsubscribePartRequestContent' is not null or undefined
            assertParamExists('unsubscribePart', 'unsubscribePartRequestContent', unsubscribePartRequestContent)
            const localVarPath = `/parts/unsubscribe/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unsubscribePartRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a list of parts from the compliance data provider subscription
         * @param {UnsubscribePartsListRequestContent} unsubscribePartsListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribePartsList: async (unsubscribePartsListRequestContent: UnsubscribePartsListRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unsubscribePartsListRequestContent' is not null or undefined
            assertParamExists('unsubscribePartsList', 'unsubscribePartsListRequestContent', unsubscribePartsListRequestContent)
            const localVarPath = `/parts/unsubscribe/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unsubscribePartsListRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update all subscribed parts in the specified PLM instance
         * @param {UpdateAllPartsRequestContent} updateAllPartsRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllParts: async (updateAllPartsRequestContent: UpdateAllPartsRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAllPartsRequestContent' is not null or undefined
            assertParamExists('updateAllParts', 'updateAllPartsRequestContent', updateAllPartsRequestContent)
            const localVarPath = `/parts/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllPartsRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specified part in the specified PLM instance
         * @param {UpdatePartRequestContent} updatePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePart: async (updatePartRequestContent: UpdatePartRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePartRequestContent' is not null or undefined
            assertParamExists('updatePart', 'updatePartRequestContent', updatePartRequestContent)
            const localVarPath = `/parts/update/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a list of parts in the specified PLM instance
         * @param {UpdatePartListRequestContent} updatePartListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartList: async (updatePartListRequestContent: UpdatePartListRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePartListRequestContent' is not null or undefined
            assertParamExists('updatePartList', 'updatePartListRequestContent', updatePartListRequestContent)
            const localVarPath = `/parts/update/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartListRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a PLM instance
         * @param {UpdatePlmInstanceRequestContent} updatePlmInstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlmInstance: async (updatePlmInstanceRequestContent: UpdatePlmInstanceRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePlmInstanceRequestContent' is not null or undefined
            assertParamExists('updatePlmInstance', 'updatePlmInstanceRequestContent', updatePlmInstanceRequestContent)
            const localVarPath = `/plm/instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlmInstanceRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancel a PLM instance\'s deletion
         * @param {CancelPlmInstanceDeletionRequestContent} cancelPlmInstanceDeletionRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPlmInstanceDeletion(cancelPlmInstanceDeletionRequestContent: CancelPlmInstanceDeletionRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelPlmInstanceDeletionResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPlmInstanceDeletion(cancelPlmInstanceDeletionRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create all missing substances in a specified PLM instance
         * @param {CreateAllMissingSubstancesRequestContent} createAllMissingSubstancesRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAllMissingSubstances(createAllMissingSubstancesRequestContent: CreateAllMissingSubstancesRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAllMissingSubstancesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAllMissingSubstances(createAllMissingSubstancesRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an internally-stored attribute
         * @param {CreateInternalAttributeRequestContent} createInternalAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalAttribute(createInternalAttributeRequestContent: CreateInternalAttributeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInternalAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalAttribute(createInternalAttributeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a missing substance in a specified PLM instance
         * @param {CreateMissingSubstanceRequestContent} createMissingSubstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissingSubstance(createMissingSubstanceRequestContent: CreateMissingSubstanceRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMissingSubstanceResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissingSubstance(createMissingSubstanceRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an attribute retrieved by a specified PLM instance
         * @param {CreatePlmAttributeRequestContent} createPlmAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlmAttribute(createPlmAttributeRequestContent: CreatePlmAttributeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePlmAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlmAttribute(createPlmAttributeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a PLM instance
         * @param {CreatePlmInstanceRequestContent} createPlmInstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlmInstance(createPlmInstanceRequestContent: CreatePlmInstanceRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePlmInstanceResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlmInstance(createPlmInstanceRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a provider attribute
         * @param {CreateProviderAttributeRequestContent} createProviderAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProviderAttribute(createProviderAttributeRequestContent: CreateProviderAttributeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProviderAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProviderAttribute(createProviderAttributeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a substance alias
         * @param {CreateSubstanceAliasRequestContent} createSubstanceAliasRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubstanceAlias(createSubstanceAliasRequestContent: CreateSubstanceAliasRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubstanceAliasResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubstanceAlias(createSubstanceAliasRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an internally-stored attribute
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInternalAttribute(attributename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInternalAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInternalAttribute(attributename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an attribute retrieved by a specified PLM instance
         * @param {string} plmid 
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlmAttribute(plmid: string, attributename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePlmAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlmAttribute(plmid, attributename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a provider attribute
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProviderAttribute(attributename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteProviderAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProviderAttribute(attributename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a string input and provides it back to the requester
         * @param {string} string 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async echo(string: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EchoResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.echo(string, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of all internally-stored attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalAttributes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInternalAttributesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalAttributes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all mappings between internal attributes and a specified PLM instance\'s attributes
         * @param {number} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlmAttributeMappings(plmid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlmAttributeMappingsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlmAttributeMappings(plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of attributes configured for a specified PLM instance
         * @param {string} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlmAttributes(plmid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlmAttributesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlmAttributes(plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all PLM instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlmInstances(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlmInstancesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlmInstances(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all mappings between internal attributes and data provider attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAttributeMappings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderAttributeMappingsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAttributeMappings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of attributes provided by the data provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAttributes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviderAttributesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAttributes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all substances
         * @param {string} [status] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubstances(status?: string, plmid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubstancesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubstances(status, plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Map an internal attribute to an attribute retrieved by a specified PLM instance
         * @param {MapPlmAttributeRequestContent} mapPlmAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapPlmAttribute(mapPlmAttributeRequestContent: MapPlmAttributeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapPlmAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapPlmAttribute(mapPlmAttributeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Map an internal attribute to a data provider attribute
         * @param {MapProviderAttributeRequestContent} mapProviderAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapProviderAttribute(mapProviderAttributeRequestContent: MapProviderAttributeRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapProviderAttributeResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapProviderAttribute(mapProviderAttributeRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a paginated list of parts
         * @param {number} [page] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveParts(page?: number, plmid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrievePartsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveParts(page, plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Schedule a PLM instance for deletion
         * @param {string} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulePlmInstanceDeletion(plmid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchedulePlmInstanceDeletionResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulePlmInstanceDeletion(plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches parts by the provided field(s)
         * @param {string} field 
         * @param {string} query 
         * @param {number} [page] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchParts(field: string, query: string, page?: number, plmid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPartsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchParts(field, query, page, plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for substances
         * @param {string} field 
         * @param {string} query 
         * @param {string} [status] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSubstances(field: string, query: string, status?: string, plmid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchSubstancesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSubstances(field, query, status, plmid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a single part to the compliance data provider subscription
         * @param {SubscribePartRequestContent} subscribePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribePart(subscribePartRequestContent: SubscribePartRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscribePartResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribePart(subscribePartRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a list of parts to the compliance data provider subscription
         * @param {SubscribePartsListRequestContent} subscribePartsListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribePartsList(subscribePartsListRequestContent: SubscribePartsListRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscribePartsListResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribePartsList(subscribePartsListRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a single part from the compliance data provider subscription
         * @param {UnsubscribePartRequestContent} unsubscribePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribePart(unsubscribePartRequestContent: UnsubscribePartRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsubscribePartResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribePart(unsubscribePartRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a list of parts from the compliance data provider subscription
         * @param {UnsubscribePartsListRequestContent} unsubscribePartsListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribePartsList(unsubscribePartsListRequestContent: UnsubscribePartsListRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsubscribePartsListResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribePartsList(unsubscribePartsListRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update all subscribed parts in the specified PLM instance
         * @param {UpdateAllPartsRequestContent} updateAllPartsRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllParts(updateAllPartsRequestContent: UpdateAllPartsRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAllPartsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllParts(updateAllPartsRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a specified part in the specified PLM instance
         * @param {UpdatePartRequestContent} updatePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePart(updatePartRequestContent: UpdatePartRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePartResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePart(updatePartRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a list of parts in the specified PLM instance
         * @param {UpdatePartListRequestContent} updatePartListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartList(updatePartListRequestContent: UpdatePartListRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePartListResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartList(updatePartListRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a PLM instance
         * @param {UpdatePlmInstanceRequestContent} updatePlmInstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlmInstance(updatePlmInstanceRequestContent: UpdatePlmInstanceRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePlmInstanceResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlmInstance(updatePlmInstanceRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Cancel a PLM instance\'s deletion
         * @param {CancelPlmInstanceDeletionRequestContent} cancelPlmInstanceDeletionRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPlmInstanceDeletion(cancelPlmInstanceDeletionRequestContent: CancelPlmInstanceDeletionRequestContent, options?: any): AxiosPromise<CancelPlmInstanceDeletionResponseContent> {
            return localVarFp.cancelPlmInstanceDeletion(cancelPlmInstanceDeletionRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create all missing substances in a specified PLM instance
         * @param {CreateAllMissingSubstancesRequestContent} createAllMissingSubstancesRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllMissingSubstances(createAllMissingSubstancesRequestContent: CreateAllMissingSubstancesRequestContent, options?: any): AxiosPromise<CreateAllMissingSubstancesResponseContent> {
            return localVarFp.createAllMissingSubstances(createAllMissingSubstancesRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an internally-stored attribute
         * @param {CreateInternalAttributeRequestContent} createInternalAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalAttribute(createInternalAttributeRequestContent: CreateInternalAttributeRequestContent, options?: any): AxiosPromise<CreateInternalAttributeResponseContent> {
            return localVarFp.createInternalAttribute(createInternalAttributeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a missing substance in a specified PLM instance
         * @param {CreateMissingSubstanceRequestContent} createMissingSubstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingSubstance(createMissingSubstanceRequestContent: CreateMissingSubstanceRequestContent, options?: any): AxiosPromise<CreateMissingSubstanceResponseContent> {
            return localVarFp.createMissingSubstance(createMissingSubstanceRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an attribute retrieved by a specified PLM instance
         * @param {CreatePlmAttributeRequestContent} createPlmAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlmAttribute(createPlmAttributeRequestContent: CreatePlmAttributeRequestContent, options?: any): AxiosPromise<CreatePlmAttributeResponseContent> {
            return localVarFp.createPlmAttribute(createPlmAttributeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a PLM instance
         * @param {CreatePlmInstanceRequestContent} createPlmInstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlmInstance(createPlmInstanceRequestContent: CreatePlmInstanceRequestContent, options?: any): AxiosPromise<CreatePlmInstanceResponseContent> {
            return localVarFp.createPlmInstance(createPlmInstanceRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a provider attribute
         * @param {CreateProviderAttributeRequestContent} createProviderAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderAttribute(createProviderAttributeRequestContent: CreateProviderAttributeRequestContent, options?: any): AxiosPromise<CreateProviderAttributeResponseContent> {
            return localVarFp.createProviderAttribute(createProviderAttributeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a substance alias
         * @param {CreateSubstanceAliasRequestContent} createSubstanceAliasRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubstanceAlias(createSubstanceAliasRequestContent: CreateSubstanceAliasRequestContent, options?: any): AxiosPromise<CreateSubstanceAliasResponseContent> {
            return localVarFp.createSubstanceAlias(createSubstanceAliasRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an internally-stored attribute
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInternalAttribute(attributename: string, options?: any): AxiosPromise<DeleteInternalAttributeResponseContent> {
            return localVarFp.deleteInternalAttribute(attributename, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an attribute retrieved by a specified PLM instance
         * @param {string} plmid 
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlmAttribute(plmid: string, attributename: string, options?: any): AxiosPromise<DeletePlmAttributeResponseContent> {
            return localVarFp.deletePlmAttribute(plmid, attributename, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a provider attribute
         * @param {string} attributename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProviderAttribute(attributename: string, options?: any): AxiosPromise<DeleteProviderAttributeResponseContent> {
            return localVarFp.deleteProviderAttribute(attributename, options).then((request) => request(axios, basePath));
        },
        /**
         * Receives a string input and provides it back to the requester
         * @param {string} string 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        echo(string: string, options?: any): AxiosPromise<EchoResponseContent> {
            return localVarFp.echo(string, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of all internally-stored attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAttributes(options?: any): AxiosPromise<GetInternalAttributesResponseContent> {
            return localVarFp.getInternalAttributes(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all mappings between internal attributes and a specified PLM instance\'s attributes
         * @param {number} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlmAttributeMappings(plmid: number, options?: any): AxiosPromise<GetPlmAttributeMappingsResponseContent> {
            return localVarFp.getPlmAttributeMappings(plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of attributes configured for a specified PLM instance
         * @param {string} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlmAttributes(plmid: string, options?: any): AxiosPromise<GetPlmAttributesResponseContent> {
            return localVarFp.getPlmAttributes(plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all PLM instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlmInstances(options?: any): AxiosPromise<GetPlmInstancesResponseContent> {
            return localVarFp.getPlmInstances(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all mappings between internal attributes and data provider attributes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAttributeMappings(options?: any): AxiosPromise<GetProviderAttributeMappingsResponseContent> {
            return localVarFp.getProviderAttributeMappings(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of attributes provided by the data provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAttributes(options?: any): AxiosPromise<GetProviderAttributesResponseContent> {
            return localVarFp.getProviderAttributes(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all substances
         * @param {string} [status] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubstances(status?: string, plmid?: string, options?: any): AxiosPromise<GetSubstancesResponseContent> {
            return localVarFp.getSubstances(status, plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Map an internal attribute to an attribute retrieved by a specified PLM instance
         * @param {MapPlmAttributeRequestContent} mapPlmAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapPlmAttribute(mapPlmAttributeRequestContent: MapPlmAttributeRequestContent, options?: any): AxiosPromise<MapPlmAttributeResponseContent> {
            return localVarFp.mapPlmAttribute(mapPlmAttributeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Map an internal attribute to a data provider attribute
         * @param {MapProviderAttributeRequestContent} mapProviderAttributeRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapProviderAttribute(mapProviderAttributeRequestContent: MapProviderAttributeRequestContent, options?: any): AxiosPromise<MapProviderAttributeResponseContent> {
            return localVarFp.mapProviderAttribute(mapProviderAttributeRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of parts
         * @param {number} [page] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParts(page?: number, plmid?: string, options?: any): AxiosPromise<RetrievePartsResponseContent> {
            return localVarFp.retrieveParts(page, plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Schedule a PLM instance for deletion
         * @param {string} plmid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulePlmInstanceDeletion(plmid: string, options?: any): AxiosPromise<SchedulePlmInstanceDeletionResponseContent> {
            return localVarFp.schedulePlmInstanceDeletion(plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches parts by the provided field(s)
         * @param {string} field 
         * @param {string} query 
         * @param {number} [page] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchParts(field: string, query: string, page?: number, plmid?: string, options?: any): AxiosPromise<SearchPartsResponseContent> {
            return localVarFp.searchParts(field, query, page, plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for substances
         * @param {string} field 
         * @param {string} query 
         * @param {string} [status] 
         * @param {string} [plmid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSubstances(field: string, query: string, status?: string, plmid?: string, options?: any): AxiosPromise<SearchSubstancesResponseContent> {
            return localVarFp.searchSubstances(field, query, status, plmid, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a single part to the compliance data provider subscription
         * @param {SubscribePartRequestContent} subscribePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePart(subscribePartRequestContent: SubscribePartRequestContent, options?: any): AxiosPromise<SubscribePartResponseContent> {
            return localVarFp.subscribePart(subscribePartRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a list of parts to the compliance data provider subscription
         * @param {SubscribePartsListRequestContent} subscribePartsListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePartsList(subscribePartsListRequestContent: SubscribePartsListRequestContent, options?: any): AxiosPromise<SubscribePartsListResponseContent> {
            return localVarFp.subscribePartsList(subscribePartsListRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a single part from the compliance data provider subscription
         * @param {UnsubscribePartRequestContent} unsubscribePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribePart(unsubscribePartRequestContent: UnsubscribePartRequestContent, options?: any): AxiosPromise<UnsubscribePartResponseContent> {
            return localVarFp.unsubscribePart(unsubscribePartRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a list of parts from the compliance data provider subscription
         * @param {UnsubscribePartsListRequestContent} unsubscribePartsListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribePartsList(unsubscribePartsListRequestContent: UnsubscribePartsListRequestContent, options?: any): AxiosPromise<UnsubscribePartsListResponseContent> {
            return localVarFp.unsubscribePartsList(unsubscribePartsListRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update all subscribed parts in the specified PLM instance
         * @param {UpdateAllPartsRequestContent} updateAllPartsRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllParts(updateAllPartsRequestContent: UpdateAllPartsRequestContent, options?: any): AxiosPromise<UpdateAllPartsResponseContent> {
            return localVarFp.updateAllParts(updateAllPartsRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specified part in the specified PLM instance
         * @param {UpdatePartRequestContent} updatePartRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePart(updatePartRequestContent: UpdatePartRequestContent, options?: any): AxiosPromise<UpdatePartResponseContent> {
            return localVarFp.updatePart(updatePartRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a list of parts in the specified PLM instance
         * @param {UpdatePartListRequestContent} updatePartListRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartList(updatePartListRequestContent: UpdatePartListRequestContent, options?: any): AxiosPromise<UpdatePartListResponseContent> {
            return localVarFp.updatePartList(updatePartListRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a PLM instance
         * @param {UpdatePlmInstanceRequestContent} updatePlmInstanceRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlmInstance(updatePlmInstanceRequestContent: UpdatePlmInstanceRequestContent, options?: any): AxiosPromise<UpdatePlmInstanceResponseContent> {
            return localVarFp.updatePlmInstance(updatePlmInstanceRequestContent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Cancel a PLM instance\'s deletion
     * @param {CancelPlmInstanceDeletionRequestContent} cancelPlmInstanceDeletionRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelPlmInstanceDeletion(cancelPlmInstanceDeletionRequestContent: CancelPlmInstanceDeletionRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cancelPlmInstanceDeletion(cancelPlmInstanceDeletionRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create all missing substances in a specified PLM instance
     * @param {CreateAllMissingSubstancesRequestContent} createAllMissingSubstancesRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAllMissingSubstances(createAllMissingSubstancesRequestContent: CreateAllMissingSubstancesRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAllMissingSubstances(createAllMissingSubstancesRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an internally-stored attribute
     * @param {CreateInternalAttributeRequestContent} createInternalAttributeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createInternalAttribute(createInternalAttributeRequestContent: CreateInternalAttributeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createInternalAttribute(createInternalAttributeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a missing substance in a specified PLM instance
     * @param {CreateMissingSubstanceRequestContent} createMissingSubstanceRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createMissingSubstance(createMissingSubstanceRequestContent: CreateMissingSubstanceRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createMissingSubstance(createMissingSubstanceRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an attribute retrieved by a specified PLM instance
     * @param {CreatePlmAttributeRequestContent} createPlmAttributeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPlmAttribute(createPlmAttributeRequestContent: CreatePlmAttributeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPlmAttribute(createPlmAttributeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a PLM instance
     * @param {CreatePlmInstanceRequestContent} createPlmInstanceRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPlmInstance(createPlmInstanceRequestContent: CreatePlmInstanceRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPlmInstance(createPlmInstanceRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a provider attribute
     * @param {CreateProviderAttributeRequestContent} createProviderAttributeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProviderAttribute(createProviderAttributeRequestContent: CreateProviderAttributeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createProviderAttribute(createProviderAttributeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a substance alias
     * @param {CreateSubstanceAliasRequestContent} createSubstanceAliasRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSubstanceAlias(createSubstanceAliasRequestContent: CreateSubstanceAliasRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSubstanceAlias(createSubstanceAliasRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an internally-stored attribute
     * @param {string} attributename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteInternalAttribute(attributename: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteInternalAttribute(attributename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an attribute retrieved by a specified PLM instance
     * @param {string} plmid 
     * @param {string} attributename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePlmAttribute(plmid: string, attributename: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePlmAttribute(plmid, attributename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a provider attribute
     * @param {string} attributename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProviderAttribute(attributename: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProviderAttribute(attributename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receives a string input and provides it back to the requester
     * @param {string} string 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public echo(string: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).echo(string, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of all internally-stored attributes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInternalAttributes(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInternalAttributes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all mappings between internal attributes and a specified PLM instance\'s attributes
     * @param {number} plmid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPlmAttributeMappings(plmid: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPlmAttributeMappings(plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of attributes configured for a specified PLM instance
     * @param {string} plmid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPlmAttributes(plmid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPlmAttributes(plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all PLM instances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPlmInstances(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPlmInstances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all mappings between internal attributes and data provider attributes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProviderAttributeMappings(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProviderAttributeMappings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of attributes provided by the data provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProviderAttributes(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProviderAttributes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all substances
     * @param {string} [status] 
     * @param {string} [plmid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubstances(status?: string, plmid?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSubstances(status, plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Map an internal attribute to an attribute retrieved by a specified PLM instance
     * @param {MapPlmAttributeRequestContent} mapPlmAttributeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mapPlmAttribute(mapPlmAttributeRequestContent: MapPlmAttributeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mapPlmAttribute(mapPlmAttributeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Map an internal attribute to a data provider attribute
     * @param {MapProviderAttributeRequestContent} mapProviderAttributeRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mapProviderAttribute(mapProviderAttributeRequestContent: MapProviderAttributeRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mapProviderAttribute(mapProviderAttributeRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of parts
     * @param {number} [page] 
     * @param {string} [plmid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveParts(page?: number, plmid?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveParts(page, plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Schedule a PLM instance for deletion
     * @param {string} plmid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public schedulePlmInstanceDeletion(plmid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).schedulePlmInstanceDeletion(plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches parts by the provided field(s)
     * @param {string} field 
     * @param {string} query 
     * @param {number} [page] 
     * @param {string} [plmid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchParts(field: string, query: string, page?: number, plmid?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchParts(field, query, page, plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for substances
     * @param {string} field 
     * @param {string} query 
     * @param {string} [status] 
     * @param {string} [plmid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchSubstances(field: string, query: string, status?: string, plmid?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchSubstances(field, query, status, plmid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a single part to the compliance data provider subscription
     * @param {SubscribePartRequestContent} subscribePartRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subscribePart(subscribePartRequestContent: SubscribePartRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).subscribePart(subscribePartRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a list of parts to the compliance data provider subscription
     * @param {SubscribePartsListRequestContent} subscribePartsListRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subscribePartsList(subscribePartsListRequestContent: SubscribePartsListRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).subscribePartsList(subscribePartsListRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a single part from the compliance data provider subscription
     * @param {UnsubscribePartRequestContent} unsubscribePartRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unsubscribePart(unsubscribePartRequestContent: UnsubscribePartRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unsubscribePart(unsubscribePartRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a list of parts from the compliance data provider subscription
     * @param {UnsubscribePartsListRequestContent} unsubscribePartsListRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unsubscribePartsList(unsubscribePartsListRequestContent: UnsubscribePartsListRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unsubscribePartsList(unsubscribePartsListRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update all subscribed parts in the specified PLM instance
     * @param {UpdateAllPartsRequestContent} updateAllPartsRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAllParts(updateAllPartsRequestContent: UpdateAllPartsRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAllParts(updateAllPartsRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a specified part in the specified PLM instance
     * @param {UpdatePartRequestContent} updatePartRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePart(updatePartRequestContent: UpdatePartRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePart(updatePartRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a list of parts in the specified PLM instance
     * @param {UpdatePartListRequestContent} updatePartListRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePartList(updatePartListRequestContent: UpdatePartListRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePartList(updatePartListRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a PLM instance
     * @param {UpdatePlmInstanceRequestContent} updatePlmInstanceRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePlmInstance(updatePlmInstanceRequestContent: UpdatePlmInstanceRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePlmInstance(updatePlmInstanceRequestContent, options).then((request) => request(this.axios, this.basePath));
    }
}

