import * as React from 'react';
import Table from '@amzn/awsui-components-react/polaris/table';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { FieldsMappingType, ParsedData } from '../types';

interface ImportedCSVDataTableProps {
    fieldsMapping: FieldsMappingType;
    parsedData: ParsedData[];
    onBack: () => void;
}
export default function ImportedCSVDataTable({
    fieldsMapping,
    parsedData,
    onBack
}: ImportedCSVDataTableProps) {
    const getColumnDefinitions = function (fieldsMapping) {
        return Object.keys(fieldsMapping).map((key: string) => {
            return {
                id: key,
                header: `${key} (csvField: ${fieldsMapping[key]})`,
                minWidth: 176,
                cell: (data) => (fieldsMapping[key] ? data[fieldsMapping[key]] : null),
                sortingField: key
            };
        });
    };
    const columnDefinitions = getColumnDefinitions(fieldsMapping);

    const { items, allPageItems, paginationProps } = useCollection(parsedData, {
        pagination: { pageSize: 10 },
        sorting: {},
        filtering: {}
    });

    return (
        <Table
            header={
                <Header
                    variant='h1'
                    description={`Review the data below to ensure it's correct before importing. Total items: ${allPageItems.length}`}
                >
                    Review and Import Data
                </Header>
            }
            renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: () => 'select all'
            }}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading resources'
            trackBy='id'
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
                    <SpaceBetween size='m'>
                        <b>No data to import</b>
                        <Button onClick={onBack}>Go back and upload CSV</Button>
                    </SpaceBetween>
                </Box>
            }
            pagination={<Pagination {...paginationProps} />}
        />
    );
}
