import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbGroup, BreadcrumbGroupProps } from '@amzn/awsui-components-react';

interface BreadcrumbsProps {
    items: BreadcrumbGroupProps.Item[];
    onFollowEnhance?: (func: () => void) => void;
}

export default function Breadcrumbs({ items, onFollowEnhance }: BreadcrumbsProps) {
    const navigate = useNavigate();
    // If the provided link is empty, do not redirect pages
    function onFollowHandler(ev) {
        ev.preventDefault();
        const navigateHandler = () => {
            if (ev.detail.href) {
                const href = ev.detail.href;
                if (href) {
                    navigate(href, { replace: true });
                }
            }
        };
        if (onFollowEnhance) {
            onFollowEnhance(navigateHandler);
        } else {
            navigateHandler();
        }
    }
    return <BreadcrumbGroup items={items} onFollow={onFollowHandler} />;
}
