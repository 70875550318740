import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import flashMessageReducer from './flashMessageSlice';
import plmReducer from './plmSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    flashMessage: flashMessageReducer,
    plm: plmReducer
});

/**
 * For unit testing.
 * @param preloadedState - state for redux.
 * @returns redux store for testing.
 */
export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    });
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export type RootState = ReturnType<typeof store.getState>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = typeof store.dispatch;
