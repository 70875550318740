import { useState, useEffect, useCallback } from 'react';
import { TableProps } from '@amzn/awsui-components-react';
import mockRetrievePartsResponse from '../../../localSettings/parts.json';
import { apiInstance } from '../../index';
import { getErrorMessage } from '../../utils/commons';
import {
    RetrievedPartData,
    RetrievePartsResponseContent,
    SearchPartsResponseContent
} from '../../open-api/generated-src/api';
import useDebounce from './useDebounce';
import { AddFlashMessageType } from './useFlashMessage';

export async function retrieveParts(
    page?: number,
    plmid?: string
): Promise<RetrievePartsResponseContent> {
    try {
        const response = await apiInstance.getApi()!.retrieveParts(page, plmid);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function searchParts(
    field: string,
    query: string,
    page?: number,
    plmid?: string
): Promise<SearchPartsResponseContent> {
    try {
        const response = await apiInstance.getApi()!.searchParts(field, query, page, plmid);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export interface ParamsType {
    pagination: {
        currentPageIndex: number;
        pageSize: number;
    };
    sorting: {
        sortingColumn: TableProps.SortingColumn<RetrievedPartData>;
        sortingDescending: boolean;
    };
    filtering: {
        filteringText: string;
        filteringField: FILTER_FIELD_TYPE;
    };
}

export type FILTER_FIELD_TYPE = { value: string; label: string };

interface RetrievePartsState {
    loading: boolean;
    parts: RetrievedPartData[];
    totalCount: number;
    currentPageIndex: number;
    filteringText: string;
    filteringField: FILTER_FIELD_TYPE;
    sortingColumn: TableProps.SortingColumn<RetrievedPartData>;
    sortingDescending: boolean;
}

export default function useParts(params: ParamsType, addFlashMessage: AddFlashMessageType) {
    const { currentPageIndex: initPageIndex } = params.pagination || {};

    const [state, setState] = useState<RetrievePartsState>({
        loading: false,
        parts: [],
        totalCount: 0,
        currentPageIndex: initPageIndex,
        filteringText: params.filtering.filteringText,
        filteringField: params.filtering.filteringField,
        sortingColumn: params.sorting.sortingColumn,
        sortingDescending: params.sorting.sortingDescending
    });

    const debouncedFilteringText = useDebounce(state.filteringText, 500);

    const setStateValue = <K extends keyof RetrievePartsState>(
        key: K,
        value: RetrievePartsState[K]
    ) => {
        setState((prev) => ({ ...prev, [key]: value }));
    };

    const setCurrentPageIndex = (value: number) => setStateValue('currentPageIndex', value);
    const setFilteringText = (value: string) => setStateValue('filteringText', value);
    const setFilteringField = (value: FILTER_FIELD_TYPE) => setStateValue('filteringField', value);
    const setSortingColumn = (value: TableProps.SortingColumn<RetrievedPartData>) =>
        setStateValue('sortingColumn', value);
    const setSortingDescending = (value: boolean) => setStateValue('sortingDescending', value);

    const retrievePartsCallback = useCallback(async () => {
        if (debouncedFilteringText !== '') {
            return;
        }
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const result = await retrieveParts(state.currentPageIndex - 1);
            setState((prev) => ({
                ...prev,
                parts: result.parts ?? [],
                totalCount: result.count ?? 0,
                loading: false
            }));
        } catch (error) {
            //TODO: remove setState after apis work
            setState((prev) => ({
                ...prev,
                parts: mockRetrievePartsResponse.parts as RetrievedPartData[],
                totalCount: mockRetrievePartsResponse.count,
                loading: false
            }));
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: `Failed to retrieve parts: ${getErrorMessage(error)}`
            });
        }
    }, [debouncedFilteringText, state.currentPageIndex, addFlashMessage]);

    const searchPartsCallback = useCallback(async () => {
        if (debouncedFilteringText === '') {
            return;
        }
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const result = await searchParts(
                state.filteringField.value,
                debouncedFilteringText,
                state.currentPageIndex - 1
            );
            setState((prev) => ({
                ...prev,
                parts: result.parts ?? [],
                totalCount: result.count ?? 0,
                loading: false
            }));
        } catch (error) {
            //TODO: remove setState after apis work
            setState((prev) => ({
                ...prev,
                parts: mockRetrievePartsResponse.parts as RetrievedPartData[],
                totalCount: mockRetrievePartsResponse.count,
                loading: false
            }));
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: `Failed to search parts: ${getErrorMessage(error)}`
            });
        }
    }, [
        addFlashMessage,
        debouncedFilteringText,
        state.filteringField.value,
        state.currentPageIndex
    ]);

    useEffect(() => {
        retrievePartsCallback();
    }, [retrievePartsCallback]);

    useEffect(() => {
        searchPartsCallback();
    }, [searchPartsCallback]);

    return {
        ...state,
        setCurrentPageIndex,
        setFilteringText,
        setFilteringField,
        setSortingColumn,
        setSortingDescending
    };
}
