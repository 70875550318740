import React, { useState } from 'react';
import CustomAppLayout from './common/CustomAppLayout';
import {
    Header,
    Table,
    Pagination,
    StatusIndicator,
    Input,
    SpaceBetween,
    TableProps,
    Button,
    Select,
    Flashbar,
    FlashbarProps,
    CollectionPreferences,
    CollectionPreferencesProps
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Breadcrumbs from './common/Breadcrumbs';
import { StatusIndicatorType } from './types';
import { getTextFilterCounterText } from '../utils/commons';
import useSubstances, {
    SubstanceTableType,
    FILTER_FIELD_TYPE,
    filterFieldOptions,
    substanceStatusOptions,
    fieldTitle,
    SubstanceStatus
} from './hooks/useSubstances';
import useFlashMessage from './hooks/useFlashMessage';

const DEFAULT_PAGESIZE = 50;

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: DEFAULT_PAGESIZE,
    wrapLines: false
};

export default function MissingSubstancesPage() {
    const [selectedSubstances, setSelectedSubstances] = useState<SubstanceTableType[]>([]);
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const { addFlashMessage } = useFlashMessage({ setFlashMessages });
    const [preferences, setPreferences] =
        useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

    const {
        loading,
        substances,
        filterText,
        filterField,
        filterSubstanceStatus,
        setFilterField,
        setFilterText,
        setFilterSubstanceStatus,
        formEditSubmitHandler,
        createMissingSubstanceHandler,
        createAllMissingSubstancesHandler
    } = useSubstances({ selectedSubstances, addFlashMessage });

    const { items, paginationProps } = useCollection(substances, {
        pagination: { pageSize: preferences.pageSize }
    });

    const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<SubstanceTableType>[] = [
        {
            id: 'id',
            cell: (item) => item.substanceId,
            header: fieldTitle.substanceId,
            minWidth: '100px'
        },
        {
            id: 'name',
            cell: (item) => item.name,
            header: fieldTitle.name,
            maxWidth: '200px'
        },
        {
            id: 'plmAlias',
            cell: (item) => item.plmAlias,
            header: 'Plm Alias',
            minWidth: '140px',
            editConfig: {
                ariaLabel: 'Plm Alias',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Plm Alias Error',
                editingCell: (item, { currentValue, setValue }) => {
                    return (
                        <Input
                            autoFocus={true}
                            value={currentValue ?? item.plmAlias}
                            onChange={(event) => setValue(event.detail.value)}
                        />
                    );
                }
            }
        },
        {
            id: 'casNumber',
            cell: (item) => item.casNumber,
            header: fieldTitle.casNumber,
            maxWidth: '200px'
        },
        {
            id: 'dataProviderNumber',
            cell: (item) => item.dataProviderNumber,
            header: fieldTitle.dataProviderNumber,
            maxWidth: '200px'
        },
        {
            id: 'status',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.status === SubstanceStatus.MISSING
                            ? StatusIndicatorType.ERROR
                            : StatusIndicatorType.SUCCESS
                    }
                >
                    {item.status.toUpperCase()}
                </StatusIndicator>
            ),
            header: 'Status',
            minWidth: '120px'
        }
    ];

    return (
        <CustomAppLayout
            notifications={<Flashbar items={flashMessages} stackItems />}
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Missing Substances',
                            href: '/missing-substances'
                        }
                    ]}
                />
            }
            content={
                <Table
                    variant='full-page'
                    columnDefinitions={COLUMN_DEFINITIONS}
                    items={items}
                    loading={loading}
                    loadingText='Loading Missing Substances'
                    wrapLines={preferences.wrapLines}
                    columnDisplay={preferences.contentDisplay}
                    submitEdit={formEditSubmitHandler}
                    selectionType='single'
                    selectedItems={selectedSubstances}
                    onSelectionChange={({ detail }) =>
                        setSelectedSubstances(detail.selectedItems as SubstanceTableType[])
                    }
                    header={
                        <Header
                            variant='h1'
                            counter={String(substances.length)}
                            actions={
                                <SpaceBetween direction='horizontal' size='s'>
                                    <Button
                                        disabled={selectedSubstances.length > 0 ? false : true}
                                        onClick={createMissingSubstanceHandler}
                                    >
                                        Add Selected Substance to PLM
                                    </Button>
                                    <Button onClick={createAllMissingSubstancesHandler}>
                                        Add All Substance to PLM
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            Missing Substances
                        </Header>
                    }
                    pagination={<Pagination {...paginationProps} />}
                    preferences={
                        <CollectionPreferences
                            title='Preferences'
                            confirmLabel='Confirm'
                            cancelLabel='Cancel'
                            preferences={preferences}
                            onConfirm={({ detail }) => setPreferences(detail)}
                            wrapLinesPreference={{
                                label: 'Wrap lines',
                                description: 'Check to see all the text and wrap the lines'
                            }}
                            pageSizePreference={{
                                options: [
                                    { value: 10, label: '10 substances' },
                                    { value: 50, label: '50 substances' },
                                    { value: 100, label: '100 substances' },
                                    { value: 200, label: '200 substances' }
                                ]
                            }}
                        />
                    }
                    filter={
                        <div className='filter-container'>
                            <div className='filter-input'>
                                <Input
                                    data-testid='input-filter'
                                    type='search'
                                    value={filterText}
                                    onChange={(event) => {
                                        setFilterText(event.detail.value);
                                    }}
                                    ariaLabel='Find Parts'
                                    placeholder='Find Parts'
                                    clearAriaLabel='clear'
                                />
                            </div>
                            <div className='filter-select'>
                                <Select
                                    data-testid='filter-field'
                                    inlineLabelText='Filter Field'
                                    options={filterFieldOptions}
                                    selectedAriaLabel='Selected'
                                    selectedOption={filterField}
                                    onChange={(event) => {
                                        setFilterField(
                                            event.detail.selectedOption as FILTER_FIELD_TYPE
                                        );
                                    }}
                                    expandToViewport={true}
                                />
                            </div>
                            <div className='filter-select'>
                                <Select
                                    data-testid='filter-status'
                                    inlineLabelText='Substance Status'
                                    options={substanceStatusOptions}
                                    selectedAriaLabel='Selected'
                                    selectedOption={filterSubstanceStatus}
                                    onChange={(event) => {
                                        setFilterSubstanceStatus(
                                            event.detail.selectedOption as FILTER_FIELD_TYPE
                                        );
                                    }}
                                    expandToViewport={true}
                                />
                            </div>
                            {filterText && (
                                <span className='filtering-results'>
                                    {getTextFilterCounterText(substances.length)}
                                </span>
                            )}
                        </div>
                    }
                />
            }
        />
    );
}
