import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { SelectProps, TableProps } from '@amzn/awsui-components-react';
import { DropdownStatusProps } from '@amzn/awsui-components-react/polaris/internal/components/dropdown-status';
import { useSelector } from 'react-redux';
import {
    GetInternalAttributesResponseContent,
    GetPlmAttributesResponseContent,
    GetProviderAttributesResponseContent,
    CreateInternalAttributeResponseContent,
    CreatePlmAttributeResponseContent,
    CreateProviderAttributeResponseContent,
    CreateInternalAttributeRequestContent,
    CreatePlmAttributeRequestContent,
    CreateProviderAttributeRequestContent,
    MapPlmAttributeRequestContent,
    MapPlmAttributeResponseContent,
    MapProviderAttributeRequestContent,
    MapProviderAttributeResponseContent,
    GetPlmAttributeMappingsResponseContent,
    GetProviderAttributeMappingsResponseContent
} from '../../open-api/generated-src/api';
import { apiInstance } from '../../index';
import { getErrorMessage } from '../../utils/commons';
import mockInternalAttributes from '../../../localSettings/internalAttributes.json';
import mockPlmAttributes from '../../../localSettings/plmAttributes.json';
import mockDataProviderAttributes from '../../../localSettings/dataProviderAttributes.json';
import mockMappingPlmAttributes from '../../../localSettings/mappingPlmAttributes.json';
import mockMappingProviderAttributes from '../../../localSettings/mappingProviderAttributes.json';
import {
    validateEmpty,
    ValidationConfigType,
    validateField
} from '../../utils/form-validation-config';
import { RootState } from '../../state/store';
import { AddFlashMessageType } from './useFlashMessage';

export const NO_MAPPING_LABEL = '--- No mapping ---';
export const CLEAR_MAPPING_LABEL = '--- Clear mapping ---';

export interface MappingAttributesType {
    internalAttribute: string;
    plmAttribute: string;
    dataProviderAttribute: string;
}

export interface CreateAttributeFormType {
    attributeCategory: string;
    attributeName: string;
    attributeType: string;
}

export type FormErrorsType = {
    attributeName: string;
    attributeType: string;
};

export const DEFAULT_FORM_DATA: CreateAttributeFormType = {
    attributeCategory: 'internalAttribute',
    attributeName: '',
    attributeType: ''
};

export default function useAttributes({
    addFlashMessage,
    formErrors,
    setFormErrors,
    formData,
    setFormData
}: {
    addFlashMessage: AddFlashMessageType;
    formErrors: FormErrorsType;
    setFormErrors: React.Dispatch<React.SetStateAction<FormErrorsType>>;
    formData: CreateAttributeFormType;
    setFormData: React.Dispatch<React.SetStateAction<CreateAttributeFormType>>;
}) {
    const plmId = useSelector((state: RootState) => state.plm.plmId);
    const [attributeMappings, setAttributeMappings] = useState<MappingAttributesType[]>([]);
    const [plmAttributesOptions, setPlmAttributesOptions] = useState<SelectProps.Options>([]);
    const [dataProviderAttributesOptions, setDataProviderAttributesOptions] =
        useState<SelectProps.Options>([]);
    const [isMappingsLoading, setIsMappingsLoading] = useState<boolean>(false);
    const [plmAttributesStatus, setPlmAttributesStatus] =
        useState<DropdownStatusProps.StatusType>('pending');
    const [dataProviderAttributeStatus, setDataProviderAttributeStatus] =
        useState<DropdownStatusProps.StatusType>('pending');

    // TODO: remove after api work
    const getMockData = () => {
        setAttributeMappings(
            mockInternalAttributes.results.map((attr) => ({
                internalAttribute: attr.attributeName!,
                plmAttribute: '',
                dataProviderAttribute: ''
            }))
        );

        // Update attributeMappings with the fetched mapping data
        setAttributeMappings((prev) =>
            prev.map((item) => ({
                ...item,
                plmAttribute:
                    mockMappingPlmAttributes?.attributes.find(
                        (m) => m.internalAttribute === item.internalAttribute
                    )?.plmAttribute ?? '',
                dataProviderAttribute:
                    mockMappingProviderAttributes?.attributes.find(
                        (m) => m.internalAttribute === item.internalAttribute
                    )?.providerAttribute ?? ''
            }))
        );
        setPlmAttributesOptions(
            mockPlmAttributes.results.map((attr) => ({
                label: attr.attributeName,
                value: attr.attributeName
            }))
        );
        setDataProviderAttributesOptions(
            mockDataProviderAttributes.results.map((attr) => ({
                label: attr.attributeName,
                value: attr.attributeName
            }))
        );
    };

    const plmAttributesOptionsWithClear = useMemo(
        () => [{ label: CLEAR_MAPPING_LABEL, value: '' }, ...plmAttributesOptions],
        [plmAttributesOptions]
    );

    const dataProviderAttributesOptionsWithClear = useMemo(
        () => [{ label: CLEAR_MAPPING_LABEL, value: '' }, ...dataProviderAttributesOptions],
        [dataProviderAttributesOptions]
    );

    const fetchAndSetData = useCallback(
        async <T>({
            apiCall,
            errorMessage,
            setData,
            errorHandler
        }: {
            apiCall: () => Promise<T>;
            errorMessage: string;
            setData?: (data: T) => void;
            errorHandler?: () => void;
        }) => {
            try {
                const result = await apiCall();
                if (setData) {
                    setData(result);
                }
                return result;
            } catch (error) {
                addFlashMessage({
                    type: 'error',
                    content: `${errorMessage} ${getErrorMessage(error)}`
                });
                if (errorHandler) {
                    errorHandler();
                }
                return null;
            }
        },
        [addFlashMessage]
    );

    const loadAttributes = useCallback(
        async (plmId: string) => {
            setIsMappingsLoading(true);

            try {
                const internalAttributes =
                    await fetchAndSetData<GetInternalAttributesResponseContent>({
                        apiCall: getInternalAttributes,
                        errorMessage: 'Failed to load internal attributes.',
                        setData: (data) => {
                            if (data.results) {
                                setAttributeMappings(
                                    data.results.map((attr) => ({
                                        internalAttribute: attr.attributeName!,
                                        plmAttribute: '',
                                        dataProviderAttribute: ''
                                    }))
                                );
                            }
                        },
                        //TODO: remove after api work
                        errorHandler: getMockData
                    });

                if (!internalAttributes) {
                    return;
                }

                const [plmMappings, providerMappings] = await Promise.all([
                    fetchAndSetData<GetPlmAttributeMappingsResponseContent>({
                        apiCall: () => getPlmAttributeMappings(plmId),
                        errorMessage: 'Failed to load plm attribute mappings.',
                        //TODO: remove after api work
                        errorHandler: getMockData
                    }),
                    fetchAndSetData<GetProviderAttributeMappingsResponseContent>({
                        apiCall: getProviderAttributeMappings,
                        errorMessage: 'Failed to load data provider attribute mappings.',
                        //TODO: remove after api work
                        errorHandler: getMockData
                    })
                ]);

                // Update attributeMappings with the fetched mapping data
                setAttributeMappings((prev) =>
                    prev.map((item) => ({
                        ...item,
                        plmAttribute:
                            plmMappings?.attributes.find(
                                (m) => m.internalAttribute === item.internalAttribute
                            )?.plmAttribute ?? '',
                        dataProviderAttribute:
                            providerMappings?.attributes.find(
                                (m) => m.internalAttribute === item.internalAttribute
                            )?.providerAttribute ?? ''
                    }))
                );

                setPlmAttributesStatus('pending');
                setDataProviderAttributeStatus('pending');

                // Fetch PLM and Provider attributes in parallel
                await Promise.all([
                    fetchAndSetData({
                        apiCall: () => getPlmAttributes(plmId),
                        errorMessage: 'Failed to load PLM attributes.',
                        setData: (data) => {
                            if (data.results) {
                                setPlmAttributesOptions(
                                    data.results.map((attr) => ({
                                        label: attr.attributeName,
                                        value: attr.attributeName
                                    }))
                                );
                            }
                        },
                        errorHandler: () => {
                            setPlmAttributesStatus('error');
                            //TODO: remove after api work
                            getMockData();
                        }
                    }),
                    fetchAndSetData({
                        apiCall: getProviderAttributes,
                        errorMessage: 'Failed to load data provider attributes.',
                        setData: (data) => {
                            if (data.results) {
                                setDataProviderAttributesOptions(
                                    data.results.map((attr) => ({
                                        label: attr.attributeName,
                                        value: attr.attributeName
                                    }))
                                );
                            }
                        },
                        errorHandler: () => {
                            setDataProviderAttributeStatus('error');
                            //TODO: remove after api work
                            getMockData();
                        }
                    })
                ]);
            } catch (error) {
                addFlashMessage({
                    type: 'error',
                    content: `An unexpected error occurred when loading the attributes. ${getErrorMessage(
                        error
                    )}`
                });
            } finally {
                setIsMappingsLoading(false);
                setPlmAttributesStatus('finished');
                setDataProviderAttributeStatus('finished');
            }
        },
        [fetchAndSetData, addFlashMessage]
    );

    const handleTableInlineEditSubmit: TableProps.SubmitEditFunction<MappingAttributesType> =
        useCallback(
            async (currentItem, column, value) => {
                const columnId = column.id as keyof MappingAttributesType;
                if (!columnId) {
                    return;
                }

                const newItem = { ...currentItem, [columnId]: value };
                const updateMappings = () => {
                    setAttributeMappings(
                        attributeMappings.map((item) =>
                            item.internalAttribute === currentItem.internalAttribute
                                ? newItem
                                : item
                        )
                    );
                };

                try {
                    let response:
                        | MapPlmAttributeResponseContent
                        | MapProviderAttributeResponseContent;
                    if (columnId === 'plmAttribute') {
                        response = await mapPlmAttribute({
                            internalAttribute: currentItem.internalAttribute,
                            plmId: plmId,
                            plmAttribute: value as string
                        });
                    } else if (columnId === 'dataProviderAttribute') {
                        response = await mapProviderAttribute({
                            internalAttribute: currentItem.internalAttribute,
                            dataProviderAttribute: value as string
                        });
                    } else {
                        throw new Error('Invalid columnId');
                    }
                    if (response) {
                        updateMappings();
                        addFlashMessage({ type: 'success', content: response.statusMessage });
                    }
                } catch (error) {
                    addFlashMessage({
                        type: 'error',
                        content: `Failed to map internalAttribute '${
                            currentItem.internalAttribute
                        }' to ${columnId} '${value}'. ${getErrorMessage(error)}`
                    });
                    // TODO: remove after API work
                    updateMappings();
                }
            },
            [attributeMappings, setAttributeMappings, plmId, addFlashMessage]
        );

    const handleFormSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            const newErrors = { ...formErrors };
            const VALIDATION_CONFIG: ValidationConfigType = {
                attributeName: [
                    { validate: validateEmpty, errorText: 'Attribute Name is required.' }
                ],
                attributeType: [
                    { validate: validateEmpty, errorText: 'Attribute Type is required.' }
                ]
            };
            ['attributeName', 'attributeType'].forEach((attribute) => {
                const { errorText } = validateField(
                    attribute,
                    VALIDATION_CONFIG,
                    formData[attribute as keyof FormData],
                    formData[attribute as keyof FormData]
                );
                newErrors[attribute] = errorText;
            });
            setFormErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

            if (!newErrors.attributeName && !newErrors.attributeType) {
                const { attributeCategory, attributeName, attributeType } = formData;
                try {
                    let response;
                    switch (attributeCategory) {
                        case 'internalAttribute':
                            response = await createInternalAttribute({
                                attributeName,
                                attributeType
                            });
                            setAttributeMappings((prevMapping) => [
                                {
                                    internalAttribute: attributeName,
                                    plmAttribute: '',
                                    dataProviderAttribute: ''
                                },
                                ...prevMapping
                            ]);
                            break;
                        case 'plmAttribute':
                            response = await createPlmAttribute({
                                plmId: plmId,
                                attributeName,
                                attributeType
                            });
                            setPlmAttributesOptions((options) => [
                                { label: attributeName, value: attributeName },
                                ...options
                            ]);
                            break;
                        case 'dataProviderAttribute':
                            response = await createProviderAttribute({
                                attributeName,
                                attributeType
                            });
                            setDataProviderAttributesOptions((options) => [
                                { label: attributeName, value: attributeName },
                                ...options
                            ]);
                            break;
                        default:
                            throw new Error('Invalid attributeCategory');
                    }

                    addFlashMessage({ type: 'success', content: response.statusMessage });
                    setFormData(DEFAULT_FORM_DATA);
                } catch (error) {
                    addFlashMessage({
                        type: 'error',
                        content: `Failed to create ${attributeCategory} '${attributeName}'. ${getErrorMessage(
                            error
                        )}`
                    });
                    //TODO: remove everything below after api work
                    setFormData(DEFAULT_FORM_DATA);
                    switch (attributeCategory) {
                        case 'internalAttribute':
                            setAttributeMappings((prevMapping) => [
                                {
                                    internalAttribute: attributeName,
                                    plmAttribute: '',
                                    dataProviderAttribute: ''
                                },
                                ...prevMapping
                            ]);
                            break;
                        case 'plmAttribute':
                            setPlmAttributesOptions((options) => [
                                { label: attributeName, value: attributeName },
                                ...options
                            ]);
                            break;
                        case 'dataProviderAttribute':
                            setDataProviderAttributesOptions((options) => [
                                { label: attributeName, value: attributeName },
                                ...options
                            ]);
                            break;
                    }
                }
            }
        },
        [
            plmId,
            formData,
            formErrors,
            setFormData,
            setFormErrors,
            setAttributeMappings,
            setPlmAttributesOptions,
            setDataProviderAttributesOptions,
            addFlashMessage
        ]
    );

    useEffect(() => {
        loadAttributes(plmId);
    }, [loadAttributes, plmId]);

    return {
        isMappingsLoading,
        plmAttributesStatus,
        dataProviderAttributeStatus,
        attributeMappings,
        setAttributeMappings,
        plmAttributesOptionsWithClear,
        setPlmAttributesOptions,
        dataProviderAttributesOptionsWithClear,
        setDataProviderAttributesOptions,
        handleTableInlineEditSubmit,
        handleFormSubmit
    };
}

export async function createInternalAttribute({
    attributeName,
    attributeType
}: CreateInternalAttributeRequestContent): Promise<CreateInternalAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createInternalAttribute({ attributeName, attributeType });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createPlmAttribute({
    plmId,
    attributeName,
    attributeType
}: CreatePlmAttributeRequestContent): Promise<CreatePlmAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createPlmAttribute({ plmId, attributeName, attributeType });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createProviderAttribute({
    attributeName,
    attributeType
}: CreateProviderAttributeRequestContent): Promise<CreateProviderAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createProviderAttribute({ attributeName, attributeType });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function mapPlmAttribute({
    internalAttribute,
    plmId,
    plmAttribute
}: MapPlmAttributeRequestContent): Promise<MapPlmAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .mapPlmAttribute({ internalAttribute, plmId, plmAttribute });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function mapProviderAttribute({
    internalAttribute,
    dataProviderAttribute
}: MapProviderAttributeRequestContent): Promise<MapProviderAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .mapProviderAttribute({ internalAttribute, dataProviderAttribute });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getInternalAttributes(): Promise<GetInternalAttributesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getInternalAttributes();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getPlmAttributes(plmid): Promise<GetPlmAttributesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getPlmAttributes(plmid);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getProviderAttributes(): Promise<GetProviderAttributesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getProviderAttributes();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getPlmAttributeMappings(
    plmid
): Promise<GetPlmAttributeMappingsResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getPlmAttributeMappings(plmid);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getProviderAttributeMappings(): Promise<GetProviderAttributeMappingsResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getProviderAttributeMappings();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
