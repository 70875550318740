import { apiInstance } from '../../index';
import {
    SubscribePartInput,
    UnsubscribePartInput,
    UpdatePartRequestContent,
    UpdatePartResponseContent,
    UpdatePartListRequestContent,
    UpdatePartListResponseContent,
    UpdateAllPartsRequestContent,
    UpdateAllPartsResponseContent
} from '../../open-api/generated-src/api';
import { getErrorMessage } from '../../utils/commons';

export enum OperationType {
    Subscribe = 'subscribe',
    Unsubscribe = 'unsubscribe'
}

export type EmptyStringRecord<T> = {
    [K in keyof T]: string;
};

interface OperationConfig {
    successMessageSingle: string;
    successMessageMultiple: string;
    errorMessageSingle: (error: unknown) => string;
    errorMessageMultiple: (error: unknown) => string;
}

export const operationConfigs: Record<OperationType, OperationConfig> = {
    [OperationType.Subscribe]: {
        successMessageSingle: 'Successfully added part to subscription.',
        successMessageMultiple: 'Successfully added parts to subscription.',
        errorMessageSingle: (error) =>
            `Failed to add part to subscription. ${getErrorMessage(error)}`,
        errorMessageMultiple: (error) =>
            `Failed to add parts to subscription. ${getErrorMessage(error)}`
    },
    [OperationType.Unsubscribe]: {
        successMessageSingle: 'Successfully removed part from subscription.',
        successMessageMultiple: 'Successfully removed parts from subscription.',
        errorMessageSingle: (error) => `Failed to remove part from subscription. ${error}`,
        errorMessageMultiple: (error) => `Failed to remove parts from subscription. ${error}`
    }
};

export type DynamicPartInput<T extends OperationType> = T extends OperationType.Subscribe
    ? SubscribePartInput
    : UnsubscribePartInput;

export async function subscribePart(part: SubscribePartInput) {
    try {
        await apiInstance.getApi()!.subscribePart(part);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function subscribePartsList(parts: SubscribePartInput[]) {
    try {
        await apiInstance.getApi()!.subscribePartsList({ parts });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function unsubscribePart(part: UnsubscribePartInput) {
    try {
        await apiInstance.getApi()!.unsubscribePart(part);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function unsubscribePartsList(parts: UnsubscribePartInput[]) {
    try {
        await apiInstance.getApi()!.unsubscribePartsList({ parts });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function updatePart({
    manufacturer,
    manufacturerPartNumber,
    plmId
}: UpdatePartRequestContent): Promise<UpdatePartResponseContent> {
    try {
        const res = await apiInstance.getApi()!.updatePart({
            manufacturer,
            manufacturerPartNumber,
            plmId
        });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function updatePartList({
    parts,
    plmId
}: UpdatePartListRequestContent): Promise<UpdatePartListResponseContent> {
    try {
        const res = await apiInstance.getApi()!.updatePartList({
            parts,
            plmId
        });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function updateAllParts({
    plmId
}: UpdateAllPartsRequestContent): Promise<UpdateAllPartsResponseContent> {
    try {
        const res = await apiInstance.getApi()!.updateAllParts({ plmId });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
