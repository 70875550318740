import React, { useEffect, useMemo, useState } from 'react';
import {
    SpaceBetween,
    Button,
    FormField,
    Input,
    Form,
    Container,
    Header,
    Flashbar,
    FlashbarProps,
    Toggle
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../common/Breadcrumbs';
import CustomAppLayout from '../common/CustomAppLayout';
import useConfirmationModal from '../hooks/useConfirmationModal';
import {
    validateEmpty,
    validateSpecialCharacter,
    ValidationConfigType,
    validateField,
    getSpecialCharacters
} from '../../utils/form-validation-config';
import { createPlmInstance, updatePlmInstance } from '../hooks/usePlmInstances';
import { AddFlashMessageType } from '../hooks/useFlashMessage';
import { getErrorMessage } from '../../utils/commons';
import useFlashMessage from '../hooks/useFlashMessage';
import useBeforeUnload from '../hooks/useBeforeUnload';

export enum OperateType {
    CREATE = 'create',
    EDIT = 'edit'
}

export interface PlmInstanceFormType {
    plmId: string;
    name: string;
    plmType: string;
    enabled: boolean;
}

export interface PlmInstanceErrorFormType {
    plmId: string;
    plmType: string;
}

const emptyFormData: PlmInstanceFormType = { plmId: '', name: '', plmType: '', enabled: false };

const defaultErrors: PlmInstanceErrorFormType = {
    plmId: '',
    plmType: ''
};

Object.keys(defaultErrors);

const fieldsToValidate = Object.keys(defaultErrors);

const plmIdSpecialCharacterRegex = new RegExp(/[^A-Za-z-]/gm);

const validationConfig: ValidationConfigType = {
    plmId: [
        { validate: validateEmpty, errorText: 'PLM ID is required.' },
        {
            validate: (value) => validateSpecialCharacter(value, plmIdSpecialCharacterRegex),
            errorText: (value: string) =>
                `The PLM ID contains invalid characters: ${getSpecialCharacters(
                    value,
                    plmIdSpecialCharacterRegex
                ).join(', ')}`
        }
    ],
    plmType: [{ validate: validateEmpty, errorText: 'PLM Type is required.' }]
};

export default function CreateOrEditPlmInstance({
    operate,
    selectedPlmInstance,
    addFlashMessage
}: {
    operate: OperateType;
    selectedPlmInstance?: PlmInstanceFormType;
    addFlashMessage: AddFlashMessageType;
}) {
    const initFormData =
        operate === OperateType.EDIT && selectedPlmInstance ? selectedPlmInstance : emptyFormData;
    const [formData, setFormData] = useState<PlmInstanceFormType>(initFormData);
    const [errors, setErrors] = useState<PlmInstanceErrorFormType>(defaultErrors);
    const [localFlashMessages, setLocalFlashMessages] = useState<FlashbarProps.MessageDefinition[]>(
        []
    );
    const navigate = useNavigate();
    const { addFlashMessage: addLocalFlashMessage } = useFlashMessage({
        setFlashMessages: setLocalFlashMessages
    });
    const isDirty = useMemo(() => {
        return JSON.stringify(formData) !== JSON.stringify(initFormData);
    }, [initFormData, formData]);

    const titleAction = operate === OperateType.CREATE ? 'Create' : 'Edit';

    useBeforeUnload(isDirty);

    const { ConfirmationModal, guardNavigation } = useConfirmationModal({
        isDirty,
        navigateHandler: () => navigate(-1),
        onConfirmHandler: () => {
            setFormData(emptyFormData);
            setErrors(defaultErrors);
            navigate(-1);
        }
    });

    function handleInputChange(field: keyof typeof formData, value: string | boolean) {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    }

    const onCancelClick = (e: CustomEvent) => {
        e.preventDefault();
        guardNavigation();
    };

    const onSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors = { ...errors };
        fieldsToValidate.forEach((attribute) => {
            const { errorText } = validateField(
                attribute,
                validationConfig,
                formData[attribute],
                formData[attribute]
            );
            newErrors[attribute] = errorText;
        });
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        const hasErrors = Object.values(newErrors).some((item) => item !== null);
        if (!hasErrors) {
            try {
                const response =
                    operate === OperateType.CREATE
                        ? await createPlmInstance(formData)
                        : await updatePlmInstance(formData);
                addFlashMessage({
                    type: 'success',
                    dismissible: true,
                    content: response.statusMessage
                });
                navigate(-1);
            } catch (err) {
                addLocalFlashMessage({
                    type: 'error',
                    dismissible: true,
                    content: `Failed to ${operate} PLM instance. ${getErrorMessage(err)}`
                });
            }
        }
    };

    useEffect(() => {
        if (operate === OperateType.EDIT && !selectedPlmInstance?.plmId) {
            navigate('/settings', { replace: true });
        }
    }, [navigate, operate, selectedPlmInstance?.plmId]);

    return (
        <CustomAppLayout
            notifications={<Flashbar items={localFlashMessages} stackItems />}
            onFollowEnhance={guardNavigation}
            breadcrumbs={
                <Breadcrumbs
                    onFollowEnhance={guardNavigation}
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Settings',
                            href: '/settings'
                        },
                        {
                            text: titleAction,
                            href: `/${operate}`
                        }
                    ]}
                />
            }
            contentType='form'
            content={
                <Container
                    header={
                        <Header variant='h1' description={`${titleAction} a PLM instance`}>
                            {`${titleAction} PLM instance`}
                        </Header>
                    }
                >
                    <>
                        <form onSubmit={onSubmitHandler}>
                            <Form
                                actions={
                                    <SpaceBetween direction='horizontal' size='xs'>
                                        <Button variant='link' onClick={onCancelClick}>
                                            Cancel
                                        </Button>
                                        <Button variant='primary'>Create</Button>
                                    </SpaceBetween>
                                }
                                errorIconAriaLabel='Error'
                            >
                                <SpaceBetween size='l'>
                                    <FormField
                                        label='PLM ID'
                                        constraintText='Valid characters are a-z, A-Z, and hypens (-).'
                                        errorText={errors.plmId}
                                    >
                                        <Input
                                            value={formData.plmId}
                                            readOnly={operate === OperateType.EDIT ? true : false}
                                            onChange={({ detail }) =>
                                                handleInputChange('plmId', detail.value)
                                            }
                                        />
                                    </FormField>
                                    <FormField
                                        label={
                                            <>
                                                PLM Name <i> - optional</i>
                                            </>
                                        }
                                        description='If not provided, it will default to PLM ID.'
                                    >
                                        <Input
                                            value={formData.name}
                                            onChange={({ detail }) =>
                                                handleInputChange('name', detail.value)
                                            }
                                        />
                                    </FormField>
                                    <FormField label='PLM Type' errorText={errors.plmType}>
                                        <Input
                                            value={formData.plmType}
                                            onChange={({ detail }) =>
                                                handleInputChange('plmType', detail.value)
                                            }
                                        />
                                    </FormField>
                                    <FormField
                                        label={
                                            <>
                                                Enable <i> - optional</i>
                                            </>
                                        }
                                    >
                                        <Toggle
                                            onChange={({ detail }) =>
                                                handleInputChange('enabled', detail.checked)
                                            }
                                            checked={formData.enabled}
                                        >
                                            Enabled
                                        </Toggle>
                                    </FormField>
                                </SpaceBetween>
                            </Form>
                        </form>
                        <ConfirmationModal />
                    </>
                </Container>
            }
        />
    );
}
