import React from 'react';
import { TopNavigation } from '@amzn/awsui-components-react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import usePlmInstances from './../hooks/usePlmInstances';
import { setPlmId } from '../../state/plmSlice';

export default function CustomTopNavigation() {
    const dispatch = useDispatch();
    const { plmId } = useSelector((state: RootState) => state.plm);
    const { loading, plmIdOptions, setPlmIdOptions } = usePlmInstances();
    const onPlmIdSelectHandler = (event) => {
        const selectedPlmId = event.detail.id;
        if (selectedPlmId) {
            dispatch(setPlmId(selectedPlmId));
            setPlmIdOptions((prevs) => {
                return prevs.map((item) => {
                    if (item.id === selectedPlmId) {
                        return { ...item, iconName: 'check' };
                    } else {
                        return { ...item, iconName: undefined };
                    }
                });
            });
        }
    };
    return (
        <TopNavigation
            identity={{
                href: '/parts',
                title: 'Compliance Crow'
            }}
            utilities={[
                {
                    type: 'menu-dropdown',
                    text: `PLM ID${plmId && `: ${plmId}`}`,
                    ariaLabel: 'PLM ID',
                    description: loading
                        ? 'Loading PLM instances'
                        : 'Choose the PLM ID to use throughout the application',
                    items: plmIdOptions,
                    onItemClick: onPlmIdSelectHandler
                }
            ]}
        />
    );
}
