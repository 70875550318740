import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PartsTablePage from './parts/PartsTablePage';
import MappingPage from './MappingPage';
import SettingsPage from './settings/SettingsPage';
import MissingSubstancesPage from './MissingSubstancesPage';
import { useSelector } from 'react-redux';
import '@amzn/awsui-global-styles/polaris.css';
import InvalidAuthPage from './auth/InvalidAuthPage';
import SignInLoadingPage from './auth/SignInLoadingPage';
import { AuthStatus } from '../state/authSlice';
import { RootState } from '../state/store';
import CustomTopNavigation from './common/CustomTopNavigation';
import './styles/table.scss';

export interface PlmIdOption {
    id: string;
    text: string;
    iconName: 'check' | undefined;
}

export default function App() {
    const authStatus = useSelector((state: RootState) => state.auth.authStatus);

    useEffect(() => {
        /**
         * According to the AWS Cloudscape documentation:
         * https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/#client-side-errors-due-to-resizeobserver
         *
         * It is recommended to filter this error. This code suppresses the visual display of the
         * ResizeObserver error in the development environment by hiding the webpack dev server overlay.
         * Note that this doesn't fix the underlying issue, but prevents the error from being displayed.
         */
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    if (authStatus === AuthStatus.invalid_profile) {
        return <InvalidAuthPage />;
    }
    if (authStatus === AuthStatus.configuring) {
        return <SignInLoadingPage />;
    }

    return (
        <>
            <CustomTopNavigation />
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Navigate to='/parts' />} />
                    <Route path='/parts/*' element={<PartsTablePage />} />
                    <Route path='/mapping' element={<MappingPage />} />
                    <Route path='/settings/*' element={<SettingsPage />} />
                    <Route path='/missing-substances' element={<MissingSubstancesPage />} />
                    <Route path='/logout' />
                </Routes>
            </BrowserRouter>
        </>
    );
}
