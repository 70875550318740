import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    GetPlmInstancesResponseContent,
    CreatePlmInstanceRequestContent,
    CreatePlmInstanceResponseContent,
    UpdatePlmInstanceRequestContent,
    UpdatePlmInstanceResponseContent,
    SchedulePlmInstanceDeletionResponseContent,
    CancelPlmInstanceDeletionRequestContent,
    CancelPlmInstanceDeletionResponseContent,
    PlmInstance
} from '../../open-api/generated-src/api';
import { apiInstance } from '../../index';
import mockPlmInstances from '../../../localSettings/plmInstances.json';
import { defaultPLMId, setPlmInstances } from '../../state/plmSlice';

export interface PlmIdOption {
    id: string;
    text: string;
    iconName: 'check' | undefined;
}

export default function usePlmInstances() {
    const [plmIdOptions, setPlmIdOptions] = useState<PlmIdOption[]>([]);

    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    const getOptions = (plmInstances: PlmInstance[]): PlmIdOption[] => {
        return plmInstances.map((plm) => {
            return {
                id: plm.plmId,
                text: plm.name,
                iconName: plm.plmId === defaultPLMId ? 'check' : undefined
            };
        });
    };

    const retrievePlmInstances = useCallback(async () => {
        setLoading(true);

        try {
            const result = await getPlmInstances();
            setPlmIdOptions(getOptions(result.plmInstances));
            dispatch(setPlmInstances(result.plmInstances));
        } catch (error) {
            //TODO: remove mock data after apis work
            setPlmIdOptions(getOptions(mockPlmInstances));
            dispatch(setPlmInstances(mockPlmInstances));
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        retrievePlmInstances();
    }, [retrievePlmInstances]);

    return {
        loading,
        plmIdOptions,
        setPlmIdOptions
    };
}

export async function getPlmInstances(): Promise<GetPlmInstancesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getPlmInstances();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createPlmInstance({
    plmId,
    plmType,
    name,
    enabled
}: CreatePlmInstanceRequestContent): Promise<CreatePlmInstanceResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createPlmInstance({ plmId, plmType, name, enabled });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function updatePlmInstance({
    plmId,
    plmType,
    name,
    enabled
}: UpdatePlmInstanceRequestContent): Promise<UpdatePlmInstanceResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .updatePlmInstance({ plmId, plmType, name, enabled });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function schedulePlmInstanceDeletion(
    plmId
): Promise<SchedulePlmInstanceDeletionResponseContent> {
    try {
        const response = await apiInstance.getApi()!.schedulePlmInstanceDeletion(plmId);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function cancelPlmInstanceDeletion({
    plmId
}: CancelPlmInstanceDeletionRequestContent): Promise<CancelPlmInstanceDeletionResponseContent> {
    try {
        const response = await apiInstance.getApi()!.cancelPlmInstanceDeletion({ plmId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
