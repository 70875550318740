import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Link,
    StatusIndicator,
    Select,
    TableProps,
    SelectProps
} from '@amzn/awsui-components-react';
import {
    StatusIndicatorType,
    Specification,
    CalculatedCompliance,
    DeclaredComplianceStatus,
    CustomBillOfMaterial,
    ComplianceStatus,
    Audit,
    Attachment
} from '../components/types';

export const SPECS_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Specification>[] = [
    {
        id: 'name',
        header: 'Specification',
        cell: (item) => item.name,
        isRowHeader: true
    },
    {
        id: 'lifeCyclePhase',
        header: 'Life Cycle Phase',
        cell: (item) => item.lifeCyclePhase
    },
    {
        id: 'calculatedCompliance',
        header: 'Calculated Compliance',
        cell: (item) => (
            <StatusIndicator
                type={
                    item.calculatedCompliance === CalculatedCompliance.COMPLIANT
                        ? StatusIndicatorType.SUCCESS
                        : StatusIndicatorType.WARNING
                }
            >
                {item.calculatedCompliance}
            </StatusIndicator>
        )
    },
    {
        id: 'declaredCompliance',
        header: 'Declared Compliance',
        cell: (item) => (
            <Link href={`/${item.declaredCompliance}`} target='_blank'>
                {item.declaredCompliance}
            </Link>
        )
    },
    {
        id: 'declaredComplianceStatus',
        header: 'Declared Compliance Status',
        editConfig: {
            ariaLabel: 'Edit Declared Compliance Status',
            errorIconAriaLabel: 'Uploaded Declared Compliance Status Error',
            editIconAriaLabel: 'editable',
            editingCell: (item, { currentValue, setValue }) => {
                const options: SelectProps.Options = [
                    {
                        value: DeclaredComplianceStatus.APPROVED,
                        label: DeclaredComplianceStatus.APPROVED
                    },
                    {
                        value: DeclaredComplianceStatus.REJECTED,
                        label: DeclaredComplianceStatus.REJECTED
                    }
                ];
                if (item.declaredComplianceStatus === DeclaredComplianceStatus.MISSING) {
                    return <></>;
                }
                return (
                    <Select
                        autoFocus={true}
                        expandToViewport={true}
                        ariaLabel='Select desired compliance status'
                        options={options}
                        onChange={(event) => {
                            setValue(event.detail.selectedOption.value);
                        }}
                        selectedOption={
                            options.find(
                                (option) =>
                                    option.value === (currentValue ?? item.declaredComplianceStatus)
                            ) ?? null
                        }
                    />
                );
            }
        },
        cell: (item) => {
            if (item.declaredComplianceStatus === DeclaredComplianceStatus.MISSING) return '';
            return (
                <StatusIndicator
                    type={
                        item.declaredComplianceStatus === DeclaredComplianceStatus.APPROVED
                            ? StatusIndicatorType.SUCCESS
                            : item.declaredComplianceStatus === DeclaredComplianceStatus.PENDING
                              ? StatusIndicatorType.PENDING
                              : StatusIndicatorType.ERROR
                    }
                >
                    {item.declaredComplianceStatus === DeclaredComplianceStatus.PENDING
                        ? 'Waiting Review'
                        : item.declaredComplianceStatus}
                </StatusIndicator>
            );
        }
    },
    {
        id: 'exemptions',
        header: 'Exemptions',
        cell: (item) => item.exemptions
    },
    {
        id: 'exemptionExpiration',
        header: 'Exemption Expiration',
        cell: (item) => item.exemptionExpiration
    },
    {
        id: 'notes',
        header: 'Notes',
        cell: (item) => item.notes
    }
];

export function useBomColumnDefinitions(): TableProps.ColumnDefinition<CustomBillOfMaterial>[] {
    const navigate = useNavigate();
    return [
        {
            id: 'name',
            header: 'Item Number',
            cell: (item) => (
                <Link
                    onClick={() => {
                        navigate(`/${item.parentPath}/${item.name}`);
                    }}
                >
                    {item.name}
                </Link>
            ),
            isRowHeader: true,
            minWidth: '50px'
        },
        {
            id: 'description',
            header: 'Item Description',
            cell: (item) => item.description,
            minWidth: '80px'
        },
        {
            id: 'complianceStatus',
            header: 'Compliance Status',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.complianceStatus === ComplianceStatus.NON_COMPLIANT
                            ? StatusIndicatorType.ERROR
                            : item.complianceStatus === ComplianceStatus.COMPLIANT
                              ? StatusIndicatorType.SUCCESS
                              : StatusIndicatorType.WARNING
                    }
                >
                    {item.complianceStatus}
                </StatusIndicator>
            ),
            minWidth: '70px',
            sortingField: 'complianceStatus'
        },
        { id: 'itemRev', header: 'Item Rev', cell: (item) => item.itemRev },
        { id: 'quantity', header: 'Size', cell: (item) => item.quantity }
    ];
}

export const AUDIT_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Audit>[] = [
    {
        id: 'id',
        header: 'id',
        cell: (item) => item.id,
        width: 100,
        isRowHeader: true
    },
    {
        id: 'operation',
        header: 'Operation',
        cell: (item) => item.operation,
        width: 200
    },
    {
        id: 'user',
        header: 'Author',
        cell: (item) => item.user,
        width: 100
    },

    {
        id: 'time',
        header: 'Time',
        cell: (item) => item.time,
        width: 200,
        sortingField: 'time'
    }
];

export const ATTACHMENT_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Attachment>[] = [
    {
        id: 'fileName',
        header: 'File Name',
        cell: (item) => (
            <Link href={item.fileLink} target='_blank'>
                {item.fileName}
            </Link>
        ),
        // cell: item => item.fileName,
        width: 100,
        isRowHeader: true
    },
    {
        id: 'fileDescription',
        header: 'File Description',
        cell: (item) => item.fileDescription,
        width: 100
    },
    {
        id: 'fileType',
        header: 'File Type',
        cell: (item) => item.fileType,
        width: 100
    },
    {
        id: 'fileSize',
        header: 'File Size',
        cell: (item) => item.fileSize,
        width: 100
    },
    {
        id: 'uploadTime',
        header: 'Upload Time',
        cell: (item) => item.uploadTime,
        width: 100
    },
    {
        id: 'uploadUser',
        header: 'Upload User',
        cell: (item) => item.uploadUser,
        width: 100
    }
];
