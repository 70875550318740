import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest, QueryParameterBag } from '@aws-sdk/types';
import { fetchAuthSession } from 'aws-amplify/auth';
import { store } from '../state/store';
import { setAuthStatus, AuthStatus } from '../state/authSlice';

// Axios interceptor that adds sigv4 headers to api calls
export default async (config: AxiosRequestConfig) => {
    const configUrl = config.url!;
    if (configUrl == undefined || configUrl.startsWith('/') || configUrl.startsWith('#')) {
        // Ignore
        return config;
    }

    let apiUrl: URL;
    try {
        apiUrl = new URL(configUrl);
    } catch (e) {
        console.log('Unable to build URL.', e);
        return config;
    }

    const { hostname, pathname, searchParams, protocol, port } = apiUrl;
    const { data, headers, method } = config;

    // Remove all the default Axios headers and leave the rest in headersToSign
    const {
        common: _common,
        delete: _delete, // 'delete' is a reserved word
        get: _get,
        head: _head,
        post: _pst,
        put: _put,
        patch: _patch,
        ...headersToSign
    } = headers as AxiosRequestHeaders;

    const { credentials } = await fetchAuthSession();
    if (!credentials) {
        store.dispatch(setAuthStatus(AuthStatus.unauthenticated));
        return config;
    }

    // The signer
    const sigv4 = new SignatureV4({
        service: 'execute-api',
        region: (/.+\.(.+?)\.amazonaws/.exec(hostname) || ['', ''])[1],
        credentials: credentials,
        sha256: Sha256
    });

    const queryBag: QueryParameterBag = {};
    for (const key of searchParams.keys()) {
        queryBag[key] = searchParams.getAll(key);
    }

    // Sign the request
    const signedRequest = await sigv4.sign({
        method: method!.toUpperCase(),
        protocol,
        hostname,
        port: +port,
        path: pathname,
        query: queryBag,
        body: data,
        headers: {
            ...headersToSign,
            // The host is required by the sigv4.sign method.
            // Ref: https://docs.aws.amazon.com/IAM/latest/UserGuide/signing-elements.html#endpoint-specification
            host: hostname
        }
    } as HttpRequest);

    // Remove unsafe header so Chrome does not complain
    delete signedRequest.headers.host;

    // Replace the original request headers
    config.headers = signedRequest.headers;

    return config;
};
