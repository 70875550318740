import React from 'react';
import { SpaceBetween, Button, Box } from '@amzn/awsui-components-react';

export function TableNoMatchState({ clearFilterHandler }: { clearFilterHandler: () => void }) {
    return (
        <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='xxs'>
                <div>
                    <b>No matches</b>
                    <Box variant='p' color='inherit'>
                        {`We can't find a match.`}
                    </Box>
                </div>
                <Button onClick={clearFilterHandler}>Clear filter</Button>
            </SpaceBetween>
        </Box>
    );
}

export function TableEmptyState({
    resourceName,
    createResourceHandler
}: {
    resourceName: string;
    createResourceHandler?: () => void;
}) {
    return (
        <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='xxs'>
                <div>
                    <b>No {resourceName.toLowerCase()}s</b>
                    <Box variant='p' color='inherit'>
                        No {resourceName.toLowerCase()}s to display
                    </Box>
                </div>
                {createResourceHandler && (
                    <Button onClick={createResourceHandler}>
                        Create {resourceName.toLowerCase()}
                    </Button>
                )}
            </SpaceBetween>
        </Box>
    );
}
