import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';

export default function ServiceNavigation({
    onFollowEnhance
}: {
    onFollowEnhance?: (func: () => void) => void;
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const activeHref = location.pathname.split('/').slice(0, 2).join('/').toLowerCase();

    // If the provided link is empty, do not redirect pages
    function onFollowHandler(ev) {
        ev.preventDefault();
        const handleNavigation = () => {
            if (ev.detail.href) {
                const href = ev.detail.href;
                if (href) {
                    navigate(href, { replace: true });
                }
            }
        };
        if (onFollowEnhance) {
            onFollowEnhance(handleNavigation);
        } else {
            handleNavigation();
        }
    }

    return <SideNavigation items={items} activeHref={activeHref} onFollow={onFollowHandler} />;
}

const items: SideNavigationProps.Item[] = [
    {
        type: 'link',
        text: 'Parts',
        href: '/parts'
    },
    {
        type: 'link',
        text: 'Mapping',
        href: '/mapping'
    },
    {
        type: 'link',
        text: 'Settings',
        href: '/settings'
    },
    {
        type: 'link',
        text: 'Missing Substances',
        href: '/missing-substances'
    }
];
