import { useEffect } from 'react';

/**
 * Custom hook to prompt the user before leaving the page if there are unsaved changes.
 *
 * @param isDirty A boolean or function that returns a boolean indicating if there are unsaved changes.
 */
export default function useBeforeUnload(isDirty: boolean) {
    useEffect(() => {
        const onBeforeUnload = (evt: BeforeUnloadEvent) => {
            if (isDirty) {
                evt.preventDefault();
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [isDirty]);
}
