import uniq from 'lodash/uniq';
type ValueType = string | undefined | null | File[];
export const validateEmpty = (value: ValueType) => Boolean(value && value.length > 0);

export const validateSpecialCharacter = (value: ValueType | undefined, regex: RegExp) => {
    if (typeof value !== 'string') {
        return true;
    }
    const isValid = !regex.test(value);

    return isValid;
};

export const getSpecialCharacters = (value: string, regex: RegExp) => {
    const specialCharacters = value.match(regex);
    return uniq(specialCharacters);
};

type ValidationFunction = (value: ValueType) => boolean;

type ValidationText = string | ((value: string) => string);

export type ValidationConfigType = Record<
    string,
    Array<{
        validate: ValidationFunction;
        errorText?: ValidationText;
        warningText?: ValidationText;
    }>
>;

export function validateField(
    attribute: string,
    validationConfig: ValidationConfigType,
    value: ValueType,
    customValue: string
) {
    const validations = validationConfig[attribute];

    for (const validation of validations) {
        const { validate, errorText, warningText } = validation;

        const isValid = validate(value);
        if (!isValid) {
            return {
                errorText: typeof errorText === 'function' ? errorText(customValue) : errorText,
                warningText:
                    typeof warningText === 'function' ? warningText(customValue) : warningText
            };
        }
    }

    return { errorText: null };
}
