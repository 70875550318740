import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlmInstance } from '../open-api/generated-src';

export const defaultPLMId = 'AgilePLM';

interface PlmState {
    plmId: string;
    plmInstances: PlmInstance[];
}

const initialState: PlmState = {
    plmId: defaultPLMId,
    plmInstances: []
};

const plmSlice = createSlice({
    name: 'plm',
    initialState,
    reducers: {
        setPlmId: (state, action: PayloadAction<string>) => {
            state.plmId = action.payload;
        },
        setPlmInstances: (state, action: PayloadAction<PlmInstance[]>) => {
            state.plmInstances = action.payload;
        }
    }
});
export const { setPlmId, setPlmInstances } = plmSlice.actions;

export default plmSlice.reducer;
