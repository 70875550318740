import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    SpaceBetween,
    Flashbar,
    Container,
    Header,
    FlashbarProps
} from '@amzn/awsui-components-react';
import Breadcrumbs from '../common/Breadcrumbs';
import CustomAppLayout from '../common/CustomAppLayout';
import { PlmInstance } from '../../open-api/generated-src';
import useFlashMessage from '../hooks/useFlashMessage';
import useConfirmationModal from '../hooks/useConfirmationModal';
import CreateOrEditPlmInstance, { OperateType } from './CreateOrEditPlmInstance';
import ConnectForm from './ConnectForm';
import PlmTable from './PlmTable';

export default function SettingsPage() {
    const [selectedPlmInstances, setSelectedPlmInstances] = useState<PlmInstance[]>([]);
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const { addFlashMessage } = useFlashMessage({ setFlashMessages });
    const { ConfirmationModal, openModal } = useConfirmationModal();
    return (
        <Routes>
            <Route
                path='create'
                element={
                    <CreateOrEditPlmInstance
                        operate={OperateType.CREATE}
                        addFlashMessage={addFlashMessage}
                    />
                }
            />
            <Route
                path='edit'
                element={
                    <CreateOrEditPlmInstance
                        operate={OperateType.EDIT}
                        selectedPlmInstance={selectedPlmInstances[0]}
                        addFlashMessage={addFlashMessage}
                    />
                }
            />
            <Route
                index
                element={
                    <CustomAppLayout
                        notifications={<Flashbar items={flashMessages} stackItems />}
                        breadcrumbs={
                            <Breadcrumbs
                                items={[
                                    {
                                        text: 'Home',
                                        href: '/parts'
                                    },
                                    {
                                        text: 'Settings',
                                        href: '/settings'
                                    }
                                ]}
                            />
                        }
                        content={
                            <Container
                                header={
                                    <Header variant='h1' description='Manage Settings and PLM'>
                                        Settings
                                    </Header>
                                }
                            >
                                <SpaceBetween size='l'>
                                    <ConnectForm />
                                    <PlmTable
                                        openModal={openModal}
                                        addFlashMessage={addFlashMessage}
                                        selectedPlmInstances={selectedPlmInstances}
                                        setSelectedPlmInstances={setSelectedPlmInstances}
                                    />
                                    <ConfirmationModal />
                                </SpaceBetween>
                            </Container>
                        }
                    />
                }
            />
        </Routes>
    );
}
