import React, { useState } from 'react';
import {
    SpaceBetween,
    Button,
    FormField,
    Input,
    Form,
    Grid,
    ExpandableSection,
    Container,
    Header
} from '@amzn/awsui-components-react';

interface ConnectFormDataType {
    plmUserName: string;
    plmPassword: string;
    dataProviderApiUrl: string;
    dataProviderApiKey: string;
}

const defaultConnectFormDataType: ConnectFormDataType = {
    plmUserName: '',
    plmPassword: '',
    dataProviderApiUrl: '',
    dataProviderApiKey: ''
};

export default function ConnectForm() {
    const [connectFormData, setConnectFormData] = useState<ConnectFormDataType>(
        defaultConnectFormDataType
    );
    function handleInputChange(field: keyof typeof connectFormData, value: string) {
        setConnectFormData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    }
    return (
        <ExpandableSection headerText='Connect To PLM and Data Provider' variant='container'>
            <form onSubmit={() => {}}>
                <Form actions={<Button variant='primary'>Save</Button>} errorIconAriaLabel='Error'>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <Container
                            header={
                                <Header variant='h3' headingTagOverride='h3'>
                                    PLM Connect Information
                                </Header>
                            }
                        >
                            <SpaceBetween size='l'>
                                <FormField label='PLM UserName'>
                                    <Input
                                        value={connectFormData.plmUserName}
                                        onChange={({ detail }) => {
                                            handleInputChange('plmUserName', detail.value);
                                        }}
                                    />
                                </FormField>
                                <FormField label='PLM Password'>
                                    <Input
                                        value={connectFormData.plmPassword}
                                        onChange={({ detail }) => {
                                            handleInputChange('plmPassword', detail.value);
                                        }}
                                        type='password'
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                        <Container
                            header={<Header variant='h3'>DataProvider Connect Information</Header>}
                        >
                            <SpaceBetween size='l'>
                                <FormField label='DataProvider API URL'>
                                    <Input
                                        value={connectFormData.dataProviderApiUrl}
                                        onChange={({ detail }) => {
                                            handleInputChange('dataProviderApiUrl', detail.value);
                                        }}
                                    />
                                </FormField>
                                <FormField label='DataProvider API Key'>
                                    <Input
                                        value={connectFormData.dataProviderApiKey}
                                        onChange={({ detail }) => {
                                            handleInputChange('dataProviderApiKey', detail.value);
                                        }}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                    </Grid>
                </Form>
            </form>
        </ExpandableSection>
    );
}
