import React, { useEffect, useState, useMemo } from 'react';
import { Wizard, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import ImportedCSVFieldsTable from './ImportedCSVFieldsTable';
import ImportedCSVDataTable from './ImportedCSVDataTable';
import ImportCSVFileContainer from './ImportCSVFileContainer';
import { CSVField, FieldsMappingType, ParsedData, DEFAULT_FIELDS_MAPPING } from '../types';
import Breadcrumbs from '../common/Breadcrumbs';
import CustomAppLayout from '../common/CustomAppLayout';
import { useNavigate } from 'react-router-dom';
import {
    subscribePartsList,
    operationConfigs,
    OperationType,
    EmptyStringRecord
} from './partSubscriptionUtils';
import { SubscribePartInput } from '../../open-api/generated-src';
import useConfirmationModal from '../hooks/useConfirmationModal';
import useFlashMessage, { AddFlashMessageType } from '../hooks/useFlashMessage';
import useBeforeUnload from '../hooks/useBeforeUnload';

export default function AddMultiplePartsPage({
    addFlashMessage
}: {
    addFlashMessage: AddFlashMessageType;
}) {
    const navigate = useNavigate();
    const [localFlashMessages, setLocalFlashMessages] = useState<FlashbarProps.MessageDefinition[]>(
        []
    );
    const [files, setFiles] = useState<File[]>([]);
    const [csvFields, setCSVFields] = useState<CSVField[]>([]);
    const [parsedData, setParsedData] = useState<ParsedData[]>([]);
    const [fieldsMapping, setFieldsMapping] = useState<FieldsMappingType>(DEFAULT_FIELDS_MAPPING);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [displayFileUploadError, setDisplayFileUploadError] = useState<boolean>(false);
    const [displayMappingError, setDisplayMappingError] = useState<boolean>(false);
    const isDirty = useMemo(() => {
        return files.length > 0;
    }, [files.length]);

    const { ConfirmationModal, guardNavigation } = useConfirmationModal({
        isDirty,
        navigateHandler: () => navigate(-1),
        onConfirmHandler: () => {
            clearAllData();
            navigate(-1);
        }
    });
    const { addFlashMessage: addLocalFlashMessage } = useFlashMessage({
        setFlashMessages: setLocalFlashMessages
    });

    useBeforeUnload(isDirty);

    const clearAllData = () => {
        setFiles([]);
        setCSVFields([]);
        setParsedData([]);
        setFieldsMapping(DEFAULT_FIELDS_MAPPING);
        setActiveStepIndex(0);
        setDisplayFileUploadError(false);
        setDisplayMappingError(false);
    };

    const onCancelClick = (e) => {
        e.preventDefault();
        guardNavigation();
    };

    const onSubmitClick = async (e) => {
        e.preventDefault();

        const subscribeInputs: SubscribePartInput[] = parsedData.map((item) => {
            const transformedItem: SubscribePartInput = {
                ...(Object.fromEntries(
                    Object.keys({} as FieldsMappingType).map((key) => [key, ''])
                ) as EmptyStringRecord<FieldsMappingType>)
            };
            Object.entries(fieldsMapping).forEach(([key, value]) => {
                transformedItem[key] = item[value] || '';
            });
            return transformedItem;
        });

        try {
            await subscribePartsList(subscribeInputs);
            addFlashMessage({
                type: 'success',
                dismissible: true,
                content: operationConfigs[OperationType.Subscribe].successMessageMultiple
            });
            navigate(-1);
        } catch (err) {
            addLocalFlashMessage({
                type: 'error',
                dismissible: true,
                content: operationConfigs[OperationType.Subscribe].errorMessageMultiple(err)
            });
        }
    };

    useEffect(() => {
        // last step
        if (activeStepIndex === 2) {
            setParsedData((prevData) => {
                return prevData.filter((item) => {
                    return Object.keys(fieldsMapping).every((key) => {
                        const csvField = fieldsMapping[key];
                        return csvField && item[csvField] && item[csvField].trim() !== '';
                    });
                });
            });
        }
    }, [activeStepIndex, fieldsMapping]);
    return (
        <CustomAppLayout
            notifications={<Flashbar items={localFlashMessages} stackItems />}
            onFollowEnhance={guardNavigation}
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Import Multiple Parts from CSV',
                            href: '/parts/import-multiple-parts-from-CSV'
                        }
                    ]}
                    onFollowEnhance={guardNavigation}
                />
            }
            contentType='wizard'
            content={
                <>
                    <Wizard
                        i18nStrings={{
                            stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                            collapsedStepsLabel: (stepNumber, stepsCount) =>
                                `Step ${stepNumber} of ${stepsCount}`,
                            skipToButtonLabel: (step) => `Skip to ${step.title}`,
                            navigationAriaLabel: 'Steps',
                            cancelButton: 'Cancel',
                            previousButton: 'Previous',
                            nextButton: 'Next',
                            submitButton: 'Submit',
                            optional: 'optional'
                        }}
                        onSubmit={onSubmitClick}
                        onCancel={onCancelClick}
                        onNavigate={({ detail }) => {
                            const nextStepIndex = detail.requestedStepIndex;
                            if (nextStepIndex === 1) {
                                if (files.length === 0) {
                                    setDisplayFileUploadError(true);
                                    return;
                                } else {
                                    setDisplayFileUploadError(false);
                                }
                            }
                            if (nextStepIndex === 2) {
                                if (
                                    fieldsMapping.manufacturer === 'None' ||
                                    fieldsMapping.manufacturerPartNumber === 'None'
                                ) {
                                    setDisplayMappingError(true);
                                    return;
                                } else {
                                    setDisplayMappingError(false);
                                }
                            }
                            setActiveStepIndex(nextStepIndex);
                        }}
                        activeStepIndex={activeStepIndex}
                        steps={[
                            {
                                title: 'Upload CSV',
                                content: (
                                    <ImportCSVFileContainer
                                        files={files}
                                        setFiles={setFiles}
                                        setParsedData={setParsedData}
                                        setCSVFields={setCSVFields}
                                        displayError={displayFileUploadError}
                                        setDisplayError={setDisplayFileUploadError}
                                    />
                                )
                            },
                            {
                                title: `Map Fields`,
                                content: (
                                    <ImportedCSVFieldsTable
                                        csvFields={csvFields}
                                        fieldsMapping={fieldsMapping}
                                        setFieldsMapping={setFieldsMapping}
                                        displayError={displayMappingError}
                                    />
                                )
                            },
                            {
                                title: 'Review & Import',
                                content: (
                                    <ImportedCSVDataTable
                                        fieldsMapping={fieldsMapping}
                                        parsedData={parsedData}
                                        onBack={clearAllData}
                                    />
                                )
                            }
                        ]}
                    />
                    <ConfirmationModal />
                </>
            }
        />
    );
}
