import React, { useState, useCallback } from 'react';
import {
    Modal,
    Box,
    SpaceBetween,
    Button,
    Alert,
    FormField,
    ColumnLayout,
    Input
} from '@amzn/awsui-components-react';

export interface ConfirmationModalProps {
    title?: string;
    content?: React.ReactNode;
    confirmLabel?: string;
    cancelLabel?: string;
    confirmConsentText?: string;
    isConfirmButtonDisabled?: boolean;
    onConfirm?: () => void;
}

function ConfirmationModal({
    modalProps,
    closeModal,
    modalVisible
}: {
    modalProps: ConfirmationModalProps;
    closeModal: () => void;
    modalVisible: boolean;
}) {
    const [deleteConsentInputText, setDeleteConsentInputText] = useState('');
    return (
        <Modal
            visible={modalVisible}
            header={modalProps.title || 'Leave page'}
            closeAriaLabel='Close modal'
            onDismiss={closeModal}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={closeModal}>
                            {modalProps.cancelLabel || 'Cancel'}
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => {
                                if (modalProps.onConfirm) {
                                    modalProps.onConfirm();
                                }
                                closeModal();
                            }}
                            disabled={
                                modalProps.confirmConsentText
                                    ? deleteConsentInputText.toLocaleLowerCase() !==
                                      modalProps.confirmConsentText
                                    : false
                            }
                        >
                            {modalProps.confirmLabel || 'Leave'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween size='m'>
                {modalProps.content ? (
                    modalProps.content
                ) : (
                    <Alert type='warning' statusIconAriaLabel='Warning'>
                        {`Are you sure that you want to leave the current page? The changes that you made won't be saved.`}
                    </Alert>
                )}
                {modalProps.confirmConsentText && (
                    <SpaceBetween size='m'>
                        <Box>
                            To avoid accidental deletions, we ask you to provide additional written
                            consent.
                        </Box>
                        <FormField
                            label={`To confirm this deletion, type "${modalProps.confirmConsentText}".`}
                        >
                            <ColumnLayout columns={2}>
                                <Input
                                    placeholder={modalProps.confirmConsentText}
                                    onChange={(event) =>
                                        setDeleteConsentInputText(event.detail.value)
                                    }
                                    value={deleteConsentInputText}
                                    ariaRequired={true}
                                />
                            </ColumnLayout>
                        </FormField>
                    </SpaceBetween>
                )}
            </SpaceBetween>
        </Modal>
    );
}

type GuardNavigationProps = {
    isDirty: boolean;
    navigateHandler: () => void;
    onConfirmHandler: () => void;
};

export default function useConfirmationModal(guardNavigationProps?: GuardNavigationProps) {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<ConfirmationModalProps>({});
    const openModal = useCallback((props: ConfirmationModalProps) => {
        setModalProps(props);
        setModalVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    const ConfirmationModalCallback = useCallback(
        () => (
            <ConfirmationModal
                modalProps={modalProps}
                closeModal={closeModal}
                modalVisible={modalVisible}
            />
        ),
        [modalVisible, modalProps, closeModal]
    );

    const guardNavigation = useCallback(() => {
        if (guardNavigationProps) {
            if (guardNavigationProps.isDirty) {
                openModal({
                    onConfirm: guardNavigationProps.onConfirmHandler
                });
            } else {
                guardNavigationProps.navigateHandler();
            }
        }
    }, [openModal, guardNavigationProps]);

    return { ConfirmationModal: ConfirmationModalCallback, openModal, guardNavigation };
}
