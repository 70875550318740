import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    usePartsColumnDefinitions,
    CONTENT_SELECTOR_OPTIONS,
    DEFAULT_PREFERENCES,
    DEFAULT_PAGESIZE
} from '../../config/table-config';
import {
    Flashbar,
    CollectionPreferences,
    Pagination,
    Table,
    TableProps,
    CollectionPreferencesProps,
    Input,
    Select,
    FlashbarProps
} from '@amzn/awsui-components-react';
import Breadcrumbs from '../common/Breadcrumbs';
import { getStringFromReactNode } from '../../utils/commons';
import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import enMessages from '@amzn/awsui-components-react/polaris/i18n/messages/all.en.json';
import CustomAppLayout from '../common/CustomAppLayout';
import PartsTableHeader from './PartsTableHeader';
import { RetrievedPartData } from '../../open-api/generated-src/api';
import { getTextFilterCounterServerSideText } from '../../utils/commons';
import useParts, { FILTER_FIELD_TYPE } from '../hooks/useParts';
import useFlashMessage from '../hooks/useFlashMessage';
import AddSinglePartPage from './AddSinglePartPage';
import AddMultiplePartsPage from './AddMultiplePartsPage';
import PartDetailsPage from '../partDetails/PartDetailsPage';

const DEFAULT_FILTER_FIELD: FILTER_FIELD_TYPE = { value: 'id', label: 'ID' };

export default function PartsTablePage() {
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const { addFlashMessage } = useFlashMessage({ setFlashMessages });
    const COLUMN_DEFINITIONS = usePartsColumnDefinitions();
    const filterFieldOptions: FILTER_FIELD_TYPE[] = COLUMN_DEFINITIONS.map((column) => ({
        value: column.id!,
        label: getStringFromReactNode(column.header)
    }));
    const [selectedParts, setSelectedParts] = useState<RetrievedPartData[]>([]);
    const [preferences, setPreferences] =
        useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

    const default_params = {
        pagination: {
            currentPageIndex: 1,
            pageSize: DEFAULT_PAGESIZE
        },
        sorting: {
            sortingColumn: { sortingField: COLUMN_DEFINITIONS[0].id },
            sortingDescending: false
        },
        filtering: {
            filteringText: '',
            filteringField: DEFAULT_FILTER_FIELD
        }
    };

    const {
        parts,
        currentPageIndex,
        setCurrentPageIndex,
        totalCount,
        loading,
        filteringText,
        setFilteringText,
        filteringField,
        setFilteringField,
        sortingColumn,
        setSortingColumn,
        sortingDescending,
        setSortingDescending
    } = useParts(default_params, addFlashMessage);

    // Keeps track of how many parts are selected
    function headerCounter(selectedParts, parts) {
        return selectedParts.length
            ? `(${selectedParts.length} of ${parts.length})`
            : `(${parts.length})`;
    }

    const handleSortingChange = ({
        detail
    }: {
        detail: TableProps.SortingState<RetrievedPartData>;
    }) => {
        setSortingDescending(detail.isDescending ?? false);
        setSortingColumn({
            sortingField: detail.sortingColumn.sortingField,
            sortingComparator: detail.sortingColumn.sortingComparator
        });
    };

    const PartTable = () => (
        <CustomAppLayout
            notifications={<Flashbar items={flashMessages} stackItems />}
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        }
                    ]}
                />
            }
            content={
                <I18nProvider locale='en' messages={[enMessages]}>
                    <Table
                        variant='full-page'
                        columnDefinitions={COLUMN_DEFINITIONS}
                        items={parts}
                        loading={loading}
                        loadingText='Loading Parts'
                        wrapLines={preferences.wrapLines}
                        columnDisplay={preferences.contentDisplay}
                        selectedItems={selectedParts}
                        onSelectionChange={({ detail }) =>
                            setSelectedParts(detail.selectedItems as RetrievedPartData[])
                        }
                        selectionType='multi'
                        stickyHeader={true}
                        sortingDisabled={true}
                        onSortingChange={handleSortingChange}
                        sortingColumn={sortingColumn}
                        sortingDescending={sortingDescending}
                        header={
                            <PartsTableHeader
                                parts={parts}
                                selectedParts={selectedParts}
                                counter={headerCounter(selectedParts, parts)}
                                columnDefinitions={COLUMN_DEFINITIONS}
                                addFlashMessage={addFlashMessage}
                            />
                        }
                        preferences={
                            <CollectionPreferences
                                title='Preferences'
                                confirmLabel='Confirm'
                                cancelLabel='Cancel'
                                preferences={preferences}
                                onConfirm={({ detail }) => setPreferences(detail)}
                                contentDisplayPreference={{
                                    title: 'Select visible columns',
                                    options: CONTENT_SELECTOR_OPTIONS
                                }}
                                wrapLinesPreference={{
                                    label: 'Wrap lines',
                                    description: 'Check to see all the text and wrap the lines'
                                }}
                            />
                        }
                        pagination={
                            <Pagination
                                currentPageIndex={currentPageIndex}
                                pagesCount={Math.ceil(
                                    totalCount / (preferences.pageSize || DEFAULT_PAGESIZE)
                                )}
                                onChange={({ detail }) =>
                                    setCurrentPageIndex(detail.currentPageIndex)
                                }
                            />
                        }
                        filter={
                            <div className='filter-container'>
                                <div className='filter-input'>
                                    <Input
                                        data-testid='input-filter'
                                        type='search'
                                        value={filteringText}
                                        onChange={(event) => {
                                            setFilteringText(event.detail.value);
                                        }}
                                        ariaLabel='Find Parts'
                                        placeholder='Find Parts'
                                        clearAriaLabel='clear'
                                    />
                                </div>
                                <div className='filter-select'>
                                    <Select
                                        data-testid='filter-field'
                                        inlineLabelText='Filter Field'
                                        options={filterFieldOptions}
                                        selectedAriaLabel='Selected'
                                        selectedOption={filteringField}
                                        onChange={(event) => {
                                            const newFilteringField = event.detail
                                                .selectedOption as FILTER_FIELD_TYPE;
                                            setFilteringField(newFilteringField);
                                        }}
                                        expandToViewport={true}
                                    />
                                </div>
                                {filteringText && (
                                    <span className='filtering-results'>
                                        {getTextFilterCounterServerSideText(
                                            parts,
                                            Math.ceil(totalCount / DEFAULT_PAGESIZE),
                                            DEFAULT_PAGESIZE
                                        )}
                                    </span>
                                )}
                            </div>
                        }
                    />
                </I18nProvider>
            }
        />
    );
    return (
        <Routes>
            <Route
                path='add-a-part'
                element={<AddSinglePartPage addFlashMessage={addFlashMessage} />}
            />
            <Route
                path='import-multiple-parts-from-CSV'
                element={<AddMultiplePartsPage addFlashMessage={addFlashMessage} />}
            />
            <Route path=':partId/*' element={<PartDetailsPage />} />
            <Route index element={<PartTable />} />
        </Routes>
    );
}
