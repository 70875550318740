import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    SpaceBetween,
    Button,
    Table,
    Box,
    Pagination,
    StatusIndicator,
    TextFilter,
    CollectionPreferences,
    Alert,
    Header,
    TableProps,
    CollectionPreferencesProps
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { PlmInstance } from '../../open-api/generated-src';
import { StatusIndicatorType } from '../types';
import { TableEmptyState, TableNoMatchState } from '../common';
import { getErrorMessage, getTextFilterCounterText } from '../../utils/commons';
import { cancelPlmInstanceDeletion, schedulePlmInstanceDeletion } from '../hooks/usePlmInstances';
import { AddFlashMessageType } from '../hooks/useFlashMessage';
import { ConfirmationModalProps } from '../hooks/useConfirmationModal';

const DISABLED_REASON_NO_SELECTION = 'Please select a PLM instance';
const DISABLED_REASON_INSTANCE_ENABLED =
    'Cannot schedule deletion for an enabled PLM instance. Please disable it first.';
const DISABLED_REASON_NO_SCHEDULE_DELETION = 'No scheduled deletion exists for this PLM instance.';

const DEFAULT_PAGESIZE = 10;

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: DEFAULT_PAGESIZE,
    wrapLines: false
};

const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<PlmInstance>[] = [
    {
        id: 'plmId',
        cell: (item) => item.plmId,
        header: 'PLM ID',
        minWidth: '80px'
    },
    {
        id: 'name',
        cell: (item) => item.name,
        header: 'PLM Name',
        minWidth: '160px'
    },
    {
        id: 'plmType',
        cell: (item) => item.plmType,
        header: 'PLM Type',
        minWidth: '100px'
    },
    {
        id: 'enabled',
        cell: (item) => (
            <StatusIndicator
                type={item.enabled ? StatusIndicatorType.SUCCESS : StatusIndicatorType.ERROR}
            >
                {item.enabled}
            </StatusIndicator>
        ),
        header: 'Enabled',
        minWidth: '100px'
    },
    {
        id: 'scheduledDeletionDate',
        cell: (item) => item.scheduledDeletionDate,
        header: 'Scheduled Deletion Date',
        minWidth: '200px'
    }
];

export default function PlmTable({
    addFlashMessage,
    openModal,
    setSelectedPlmInstances,
    selectedPlmInstances
}: {
    addFlashMessage: AddFlashMessageType;
    openModal: (props: ConfirmationModalProps) => void;
    selectedPlmInstances: PlmInstance[];
    setSelectedPlmInstances: React.Dispatch<React.SetStateAction<PlmInstance[]>>;
}) {
    const { plmId, plmInstances } = useSelector((root: RootState) => root.plm);
    const [preferences, setPreferences] =
        useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

    const [isDeleteButtonEnable, setIsDeleteButtonEnable] = useState<boolean>(false);
    const [isCancelDeleteButtonEnable, setIsCancelDeleteButtonEnable] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const deletePlmInstanceHandler = async () => {
        setIsDeleteButtonEnable(false);
        const plmInstance = selectedPlmInstances[0];
        try {
            if (selectedPlmInstances.length < 1) {
                throw Error(DISABLED_REASON_NO_SELECTION);
            }
            if (plmInstance.enabled) {
                addFlashMessage({
                    type: 'error',
                    dismissible: true,
                    content: DISABLED_REASON_INSTANCE_ENABLED
                });
                return;
            }
            const response = await schedulePlmInstanceDeletion(plmInstance.plmId);
            addFlashMessage({
                type: 'success',
                dismissible: true,
                content: response.statusMessage
            });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: `Failed to schedule deletion for PLM instance '${plmInstance.plmId}'. ${getErrorMessage(error)}`
            });
        } finally {
            setIsDeleteButtonEnable(true);
        }
    };

    const clickDeleteButtonHandler = () => {
        if (selectedPlmInstances.length !== 1) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: 'Please select exactly one PLM instance to cancel deletion.'
            });
            return;
        }

        openModal({
            title: `Schedule PLM Instance Deletion`,
            content: (
                <SpaceBetween size='m'>
                    <Box variant='span'>
                        Schedule deletion for PLM instance{' '}
                        <Box variant='span' fontWeight='bold'>
                            {plmId}
                        </Box>
                        {`? You can’t undo this action.`}
                    </Box>

                    <Alert type='warning' statusIconAriaLabel='Warning'>
                        Please note that the actual deletion and internal resource cleanup will be
                        scheduled to occur two weeks after this confirmation.
                    </Alert>
                </SpaceBetween>
            ),
            confirmConsentText: 'confirm',
            confirmLabel: 'Delete',
            onConfirm: deletePlmInstanceHandler
        });
    };

    const cancelDeletePlmInstanceHandler = async () => {
        setIsCancelDeleteButtonEnable(false);
        const plmInstance = selectedPlmInstances[0];
        try {
            if (selectedPlmInstances.length !== 1) {
                throw Error(DISABLED_REASON_NO_SELECTION);
            }
            const response = await cancelPlmInstanceDeletion({ plmId: plmInstance.plmId });
            addFlashMessage({
                type: 'success',
                dismissible: true,
                content: response.statusMessage
            });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: `Failed to cancel the scheduled deletion for PLM instance '${plmInstance.plmId}'. ${getErrorMessage(error)}`
            });
        } finally {
            setIsCancelDeleteButtonEnable(true);
        }
    };

    const clickCancelDeleteButtonHandler = () => {
        if (selectedPlmInstances.length !== 1) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: 'Please select exactly one PLM instance to cancel deletion.'
            });
            return;
        }
        const plmInstance = selectedPlmInstances[0];
        openModal({
            title: `Cancel Scheduled PLM Instance Deletion`,
            content: (
                <SpaceBetween size='m'>
                    <Box variant='span'>
                        Cancel scheduled deletion for PLM instance{' '}
                        <Box variant='span' fontWeight='bold'>
                            {plmInstance.plmId}
                        </Box>
                        {`? You can't undo this action.`}
                    </Box>
                </SpaceBetween>
            ),
            confirmLabel: 'Cancel Deletion',
            onConfirm: cancelDeletePlmInstanceHandler
        });
    };

    const navigateToCreatePage = () => {
        navigate(`${location.pathname}/create`);
    };

    const { items, actions, filteredItemsCount, filterProps, paginationProps, collectionProps } =
        useCollection(plmInstances, {
            pagination: { pageSize: preferences.pageSize },
            filtering: {
                empty: (
                    <TableEmptyState
                        resourceName='PLM Instance'
                        createResourceHandler={navigateToCreatePage}
                    />
                ),
                noMatch: <TableNoMatchState clearFilterHandler={() => actions.setFiltering('')} />
            }
        });

    useEffect(() => {
        const hasOneInstanceSelected = selectedPlmInstances.length === 1;
        const isInstanceDisabled = hasOneInstanceSelected && !selectedPlmInstances[0].enabled;
        const isScheduledDeleted =
            hasOneInstanceSelected && selectedPlmInstances[0].scheduledDeletionDate !== '';
        setIsDeleteButtonEnable(isInstanceDisabled);
        setIsCancelDeleteButtonEnable(isScheduledDeleted);
    }, [selectedPlmInstances]);

    return (
        <Table
            {...collectionProps}
            header={
                <Header
                    variant='h2'
                    description='Manage PLM Instances'
                    actions={
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Button
                                data-testid='header-btn-edit'
                                disabled={selectedPlmInstances.length !== 1}
                                onClick={navigateToCreatePage}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={clickDeleteButtonHandler}
                                data-testid='header-btn-delete'
                                disabled={!isDeleteButtonEnable}
                                disabledReason={
                                    selectedPlmInstances.length === 0
                                        ? DISABLED_REASON_NO_SELECTION
                                        : selectedPlmInstances[0].enabled
                                          ? DISABLED_REASON_INSTANCE_ENABLED
                                          : undefined
                                }
                            >
                                Delete
                            </Button>
                            <Button
                                onClick={clickCancelDeleteButtonHandler}
                                data-testid='header-btn-delete'
                                disabled={!isCancelDeleteButtonEnable}
                                disabledReason={
                                    selectedPlmInstances.length === 0
                                        ? DISABLED_REASON_NO_SELECTION
                                        : selectedPlmInstances[0].scheduledDeletionDate === ''
                                          ? DISABLED_REASON_NO_SCHEDULE_DELETION
                                          : undefined
                                }
                            >
                                Cancel Delete
                            </Button>
                            <Button
                                data-testid='header-btn-create'
                                variant='primary'
                                onClick={() => navigate(`${location.pathname}/create`)}
                            >
                                Create PLM Instance
                            </Button>
                        </SpaceBetween>
                    }
                >
                    PLM Instances
                </Header>
            }
            items={items}
            columnDefinitions={COLUMN_DEFINITIONS}
            variant='container'
            loadingText='Loading plm'
            selectionType='single'
            selectedItems={selectedPlmInstances}
            onSelectionChange={({ detail }) =>
                setSelectedPlmInstances(detail.selectedItems as PlmInstance[])
            }
            pagination={<Pagination {...paginationProps} />}
            preferences={
                <CollectionPreferences
                    title='Preferences'
                    confirmLabel='Confirm'
                    cancelLabel='Cancel'
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                    wrapLinesPreference={{
                        label: 'Wrap lines',
                        description: 'Check to see all the text and wrap the lines'
                    }}
                    pageSizePreference={{
                        options: [
                            { value: 10, label: '10 PLM Instances per page' },
                            { value: 20, label: '20 PLM instances per page' },
                            { value: 50, label: '50 PLM instances per page' }
                        ]
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    filteringAriaLabel='Filter PLM instances'
                    filteringPlaceholder='Find PLM instances'
                    filteringClearAriaLabel='Clear'
                    countText={
                        filteredItemsCount ? getTextFilterCounterText(filteredItemsCount) : ''
                    }
                />
            }
        />
    );
}
